/*
 * @Author: mengllingshuai menglingshuai@busybiz.cn
 * @Date: 2022-06-22 15:53:38
 * @LastTime: 2023-04-18 17:19:59
 * @LastAuthor: liudanli 565478958@qq.com
 * @FilePath: \unicew-nuxt\plugins\vuePersistedstate.js
 * @Description: Do not Description
 */
import createPersistedState from "vuex-persistedstate";

export default ({ store }) => {
  createPersistedState({
    storage: sessionStorage,
    paths: ["user", "cart", "address"],
  })(store);
};

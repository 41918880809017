/*
 * @Author: likai 770151764@qq.com
 * @Date: 2022-09-30 11:36:44
 * @LastTime: 2022-11-24 16:42:16
 * @LastAuthor: mengllingshuai menglingshuai@busybiz.cn
 * @FilePath: /jurllyshe-nuxt/plugins/vconsole.js
 * @Description: Do not Description
 */
// import VConsole from "vconsole";
// // const vConsole = process.env.GENERATE_ENV === "generate" ? new VConsole() : ''
// // const vConsole = process.env.NODE_ENV === "production" ? new VConsole() : "";
// // const vConsole = process.env.NODE_ENV === "development" ? new VConsole() : "";
// const vConsole = new VConsole();
// export default vConsole;

/*
 * @Author: mengllingshuai menglingshuai@busybiz.cn
 * @Date: 2022-08-08 17:00:57
 * @LastTime: 2023-06-13 19:34:36
 * @LastAuthor: liudanli 565478958@qq.com
 * @FilePath: \unicew-nuxt\api\homeApi.js
 * @Description: Do not Description
 */
export default ({ $axios }, inject) => {
  // 获取top 栏
  inject("homeTop", () =>
    $axios({
      url: `/api/home/top`,
      method: "get",
    })
  );
  // 获取首页数据
  inject("homeData", () =>
    $axios({
      url: `/api/home/index`,
      method: "get",
    })
  );
  // 获取首页博客
  inject("homeBlog", () =>
    $axios({
      url: `/api/home/blog`,
      method: "get",
    })
  );
  // 获取首页视频
  inject("homeVideo", (data) =>
    $axios({
      url: `/api/home/video`,
      method: "get",
      params: data,
    })
  );

  //加主屏优惠券
  inject("getScreenCoupon", () =>
    $axios({
      url: `/api/coupon/receive/main_screen`,
      method: "post",
    })
  );
  inject("getScreenCouponInfo", () =>
    $axios({
      url: `/api/coupon/receive/main_screen`,
      method: "get",
    })
  );
  // 注入一个方法
  inject("toArrObj", (str) => {
    if (!str) {
      return null;
    }
    try {
      // 分割字符串为键值对数组
      const pairs = str.split(",");
      // 创建空对象
      const obj = {};
      // 遍历键值对数组
      pairs.forEach((pair) => {
        // 分割键值对
        const [key, value] = pair.split(":");
        // 去除键和值的空格
        const trimmedKey = key.trim();
        const trimmedValue = value.trim();
        // 将键值对添加到对象中
        obj[trimmedKey] = trimmedValue;
      });
      return obj;
    } catch (error) {
      console.log("ga后台配置错误" + error);
    }
  });
};

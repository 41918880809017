//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    text: { type: String },
    time: { type: Number },
    iconName: { type: String },
  },
  mounted() {
    this.$nextTick(() => {
      this.isShow = true;
      this.timer = setTimeout(() => {
        this.hideToast();
      }, this.time);
    });
  },
  data() {
    return {
      isShow: null,
      timer: null,
    };
  },
  watch: {
    isShow(val) {
      if (!val) {
        this.close();
        document.body.removeChild(this.$el);
      }
    },
  },
  methods: {
    hideToast() {
      this.isShow = false;
      clearTimeout(this.timer);
      this.timer = null;
    },
  },
};

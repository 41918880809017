const middleware = {}

middleware['auth'] = require('../pagePC/middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['popoup'] = require('../pagePC/middleware/popoup.js')
middleware['popoup'] = middleware['popoup'].default || middleware['popoup']

middleware['ssrHeader'] = require('../pagePC/middleware/ssrHeader.js')
middleware['ssrHeader'] = middleware['ssrHeader'].default || middleware['ssrHeader']

export default middleware

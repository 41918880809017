/*
 * @Author: mengllingshuai menglingshuai@busybiz.cn
 * @Date: 2022-07-07 09:58:07
 * @LastTime: 2023-05-23 10:16:30
 * @LastAuthor: liudanli 565478958@qq.com
 * @FilePath: \unicew-nuxt\plugins\toast.js
 * @Description: Do not Description
 */
import Vue from "vue";
import showToast from "~/components/Toast";
// import showLoginPop from "~/components/Pop";
// import showRegisterPop from "~/components/Pop/register";
Vue.prototype.$toast = showToast;
// Vue.prototype.$poplogin = showLoginPop;
// Vue.prototype.$popregister = showRegisterPop;
export default ({ app }) => {
  app.$toast = showToast;
};

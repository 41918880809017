/*******
 * @Author: liudanli
 * @Date: 2023-06-26 15:46:11
 * @LastEditTime: Do not edit
 * @description:
 * @return {*}
 */
export const state = () => ({
  addressInfo: {},
  billingAddressInfo: {},
  confirmFormValid: false,
  confirmFormValidBilling: true,
  isEdit: false,
});
export const getters = {
  addressInfo(state) {
    return state.addressInfo;
  },
  billingAddressInfo(state) {
    return state.billingAddressInfo;
  },
  confirmFormValid(state) {
    return state.confirmFormValid;
  },
  confirmFormValidBilling(state) {
    return state.confirmFormValidBilling;
  },
  isEdit(state) {
    return state.isEdit;
  },
};
export const mutations = {
  setAddressInfo(state, data) {
    if (JSON.stringify(data) == "{}") {
      state.addressInfo = {};
    } else {
      for (const key in data) {
        state.addressInfo[key] = data[key];
      }
    }
  },
  setBillingAddressInfo(state, data) {
    if (JSON.stringify(data) == "{}") {
      state.billingAddressInfo = {};
    } else {
      for (let key in data) {
        state.billingAddressInfo[key] = data[key];
      }
    }
  },
  setConfirmFormValid(state, data) {
    state.confirmFormValid = data;
  },
  setConfirmFormValidBilling(state, data) {
    state.confirmFormValidBilling = data;
  },
  setIsEdit(state, data) {
    console.log(data, "data");
    state.isEdit = data;
  },
};
export const actions = {};

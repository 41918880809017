/*
 * @Author: liudanli 565478958@qq.com
 * @Date: 2023-03-02 10:48:19
 * @LastTime: 2023-03-02 10:52:48
 * @LastAuthor: liudanli 565478958@qq.com
 * @FilePath: \unicew-nuxt\api\wishApi.js
 * @Description: Do not Description
 */
export default ({ $axios }, inject) => {
  // 获取心愿单列表
  inject("getWishList", (data) =>
    $axios({
      url: `api/wishlists`,
      method: "get",
      params: data,
    })
  );
  // 添加心愿单
  inject("addWishList", (data) =>
    $axios({
      url: `api/wishlists`,
      method: "post",
      data,
    })
  );
  //移除商品心愿单
  inject("removeWish", (data) =>
    $axios({
      url: `api/wishlists/${data.id}`,
      method: "delete",
    })
  );
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    // 父辈向子辈传参
  },
  data() {
    return {
      email: "",
      footerList: [
        {
          title: "about us",
          listItem: [],
        },
        {
          title: "need help",
          listItem: [],
        },
        {
          title: "contact us",
          listItem: [],
        },
      ],
      shareList: [
        {
          icon: "icon-facebook_box_fill",
          link: "https://www.facebook.com/velvetbyunice",
          type: "facebook",
        },
        {
          icon: "icon-pinterest_fill",
          link: "https://www.pinterest.com/unicecom/",
          type: "pinterest",
        },
        {
          icon: "icon-instagram-fill",
          link: "https://www.instagram.com/velvethairextension/",
          type: "instagram",
        },
        {
          icon: "icon-youtube-fill",
          link: "https://www.youtube.com/c/UniceHair",
          type: "youtube",
        },
        {
          icon: "icon-twitter-fill",
          link: "https://twitter.com/unicehair",
          type: "twitter",
        },
        {
          icon: "icon-twitter-fill-1",
          link: "https://www.tiktok.com/@velvethair01",
          type: "tiktok",
        },
      ],
    };
  },
  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
  },
  components: {
    // 组件的引用
  },
  methods: {
    // 方法
    //提交订阅
    /**
     * @Author: liudanli 565478958@qq.com
     * @Date: 2023-05-20 08:21:18
     * @LastAuthor: liudanli 565478958@qq.com
     * @description:  对邮箱进行验证
     * @return {*}
     * @Description: Do not Description
     */
    submit() {
      try {
        this.$validator.validateAll().then((valid) => {
          if (valid == true) {
            this.newsletterHandler();
          }
        });
      } catch (error) {
        throw new Error("底部订阅报错" + error);
      }
    },
    /**
     * @Author: liudanli 565478958@qq.com
     * @Date: 2023-05-20 08:22:34
     * @LastAuthor: liudanli 565478958@qq.com
     * @description: 提交form 表单数据
     * @return {*}
     * @Description: Do not Description
     */
    async newsletterHandler() {
      let { code, message } = await this.$newsletter({
        email: this.email,
      });
      if (code == 200000) {
        this.$toast({ type: "success", content: message });
        this.googleCustomeEventHanlder("subscribe");
      }
    },
    /**
     * @Author: liudanli 565478958@qq.com
     * @Date: 2023-05-20 08:17:05
     * @LastAuthor: liudanli 565478958@qq.com
     * @description: 获取底部信息
     * @return {*}
     * @Description: Do not Description
     */
    async getFooterInfo() {
      try {
        let { code, data } = await this.$getSystemConfigInfo({
          module: "system_other_configs",
        });
        if (code == 200000) {
          this.footerList.map((item) => {
            if (item.title == "about us") {
              item.listItem = data.footerAboutUs;
            } else if (item.title == "need help") {
              item.listItem = data.footerNeedHelp;
            } else if (item.title == "contact us") {
              item.listItem = data.footerContactUs;
            }
          });
        }
      } catch (error) {
        throw new Error("获取底部媒体链接" + error);
      }
    },
  },
  mounted() {
    // 页面进入时加载内容
    this.getFooterInfo();
  },
  watch: {
    // 监测变化
  },
};

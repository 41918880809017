/*
 * @Author: liudanli 565478958@qq.com
 * @Date: 2023-05-19 17:25:16
 * @LastAuthor: liudanli 565478958@qq.com
 * @LastTime: 2023-06-16 08:27:37
 * @FilePath: \unicew-nuxt\pagePC\store\index.js
 * @Description: Do not Description
 */
export const state = () => ({
  pageInfoUrl: null,
  productInfo: {},
  guestToken: null,
  searchProductListData: [],
  productAvailableSortBy: [],
  seoInfo: null,
  customMarkList: [
    // 用户来源标识
    "gclid",
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
    "msclkid",
    "fbclid",
    "fclid",
    "rfsn",
    "sscid",
    "mc_cid",
    "mc_eid",
    "wbraid",
    "gbraid",
    "irgwc",
    "irclickid",
    "irpid",
    "irmpname",
    "cjevent",
    "cm_mmc",
    "cjdata",
    "sms_clicked_window",
    "sms_received_window",
    "email_opened_window",
    "wtbap",
    "awc",
  ],
  navigationData: null,
  topInfo: null,
  pageType: "", //当routername 为all时存一下当前页面类型
});
export const getters = {
  productInfo(state) {
    return state.productInfo;
  },
  guestToken(state) {
    return state.guestToken;
  },
  pageInfoUrl(state) {
    return state.pageInfoUrl;
  },
  searchProductListData(state) {
    return state.searchProductListData;
  },
  seoInfo(state) {
    return state.seoInfo;
  },
  customMarkList(state) {
    return state.customMarkList;
  },
  pageType(state) {
    return state.pageType;
  },
  getNavigationData(state) {
    return state.navigationData;
  },
  getTopInfo(state) {
    return state.topInfo;
  },
};
export const mutations = {
  setProductInfo(state, data) {
    state.productInfo = data;
  },
  setGuestToken(state, data) {
    state.guestToken = data;
  },
  setPageInfoUrl(state, data) {
    state.pageInfoUrl = data;
  },
  setSearchProductListData(state, { data, isAdd }) {
    state.productListData = isAdd ? [...state.productListData, ...data] : [...data];
  },
  setSeoInfo(state, data) {
    state.seoInfo = data;
  },
  setPageType(state, data) {
    state.pageType = data;
  },
  setNavigationData(state, data) {
    state.navigationData = data;
  },
  setTopInfo(state, data) {
    state.topInfo = data;
  },
};
export const actions = {
  async nuxtServerInit({ commit }) {
    // 在此处执行服务端初始化逻辑
    // 可以在这里获取一些通用的数据并存储到 Vuex store 中
    // 例如，可以在服务端获取用户验证信息、站点配置等
    // 可以使用 req 对象来访问请求相关的数据，例如请求头、Cookies 等
    // 使用 commit 方法来调用 Vuex store 的 mutations
    // console.log(req, "req 是啥");
    try {
      let [topInfoRes, categoryListRes] = await Promise.all([this.$homeTop(), this.$navData()]);
      console.log(categoryListRes.data, "menu");
      commit("setTopInfo", topInfoRes.data.configs.topBanner);
      commit("setNavigationData", categoryListRes.data.category);
      // if (this.topInfo.topEndTime) {
      //这儿应该加个配置 倒计时是否开启
      //   this.countdown();
      // }
    } catch (error) {
      throw new Error("获取顶部信息" + error);
    }
  },
};

import { render, staticRenderFns } from "./hasHead.vue?vue&type=template&id=8e6b9202&"
import script from "./hasHead.vue?vue&type=script&lang=js&"
export * from "./hasHead.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeadNav: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pagePC/components/HeadNav.vue').default,Footer: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pagePC/components/Footer.vue').default})

/*
 * @Author: liudanli 565478958@qq.com
 * @Date: 2023-02-17 17:59:58
 * @LastTime: 2023-03-28 09:10:12
 * @LastAuthor: liguohua 2490322461@qq.com
 * @FilePath: \unicew-nuxt\api\userApi.js
 * @Description: Do not Description
 */

export default ({ $axios }, inject) => {
  //注册提交表单
  inject("submitRegiser", (data) =>
    $axios({
      url: `/api/auth/register`,
      method: "post",
      data,
    })
  );
  //登陆提交表单
  inject("submitLogin", (data, url) =>
    $axios({
      url: url,
      method: "post",
      data,
    })
  );
  //获取区号
  inject("getAreaCode", (data) =>
    $axios({
      url: "/api/auth/login",
      method: "post",
      data,
    })
  );
  //获取验证码
  inject("getCode", (data) =>
    $axios({
      url: "/api/auth/login/code",
      method: "post",
      data,
    })
  );
  //退出登陆
  inject("logOut", () =>
    $axios({
      url: "/api/auth/logout",
      method: "get",
    })
  );
  /** -------------- 忘记密码 */
  inject("forgetPasswordSendEmail", (data) =>
    $axios({
      url: "/api/customer/change_forggoten",
      method: "post",
      data,
    })
  );
  inject("forgetPasswordValidateToken", (data) =>
    $axios({
      url: "/api/customer/forgot_password",
      method: "post",
      data,
    })
  );
  inject("forgetPasswordUpdate", (data) =>
    $axios({
      url: "/api/customer/forgot_update",
      method: "post",
      data,
    })
  );
  //获取用户的信息
  inject("getUserInfoAll", () =>
    $axios({
      url: "/api/customer/account",
      method: "get",
    })
  );
  //获取用户的信息 //临时使用
  inject("getUserInfo", () =>
    $axios({
      url: "/api/customer/info",
      method: "get",
    })
  );
  //获取订单
  inject("orderList", (data) =>
    $axios({
      url: "/api/orders",
      method: "get",
      params: data,
    })
  );
  inject("orderDetail", (id) =>
    $axios({
      url: `/api/orders/${id}`,
      method: "get",
    })
  );
  // 获取积分历史记录
  inject("pointHistories", (page) =>
    $axios({
      url: `/api/point_histories`,
      method: "get",
      params: page,
    })
  );
  // 获取用户coupon
  inject("getCoupon", (data) =>
    $axios({
      url: `/api/customer/coupons`,
      method: "get",
      params: data,
    })
  );
  //上传头像
  inject("editorAvatar", (data) =>
    $axios({
      url: "/api/customer/avatar",
      method: "post",
      data,
    })
  );
  //修改用户信息
  inject("changeInfo", (data) =>
    $axios({
      url: "/api/customer/update",
      method: "post",
      data,
    })
  );
  //修改密码
  inject("changePassword", (data) =>
    $axios({
      url: "/api/customer/change_password",
      method: "post",
      data,
    })
  );
  // 第三方登录
  inject("thridLogin", (data) =>
    $axios({
      url: `api/auth/oauth`,
      method: "post",
      data,
    })
  );

  //根据用户行为 增加积分
  inject("addPointByBehavior", (data) =>
    $axios({
      url: `/api/common/add_point_by_behavior`,
      method: "post",
      data,
    })
  );
};

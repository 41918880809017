/*
 * @Author: mengllingshuai menglingshuai@busybiz.cn
 * @Date: 2022-05-23 08:50:50
 * @LastTime: 2023-03-31 09:35:33
 * @LastAuthor: liguohua 2490322461@qq.com
 * @FilePath: \unicew-nuxt\api\productApi.js
 * @Description: 产品
 */
export default ({ $axios }, inject) => {
  //商品列表
  inject("products", (data) =>
    $axios({
      url: `api/products`,
      method: "get",
      params: data,
    })
  );
  //商品列表
  inject("productsOptionList", (data) =>
    $axios({
      url: `api/products/list`,
      method: "get",
      params: data,
    })
  );
  //商品详情
  inject("productsDetail", (data) =>
    $axios({
      url: `api/products/${data.id}`,
      method: "get",
      params: data,
    })
  );
  // 捆绑商品
  inject("productsBundle", (data) =>
    $axios({
      url: `api/products/bundle`,
      method: "get",
      params: data,
    })
  );
  // 推荐商品
  inject("getRecmmondPrduct", (data) =>
    $axios({
      url: `api/products/linked/${data.id}`,
      method: "get",
      params: { cid: data.cid || undefined },
    })
  );

  //faq
  inject("productsFaq", (data) =>
    $axios({
      url: `api/product_faq`,
      method: "get",
      params: data,
    })
  );
  //howto
  inject("howToContent", (data) =>
    $axios({
      url: `api/product_how_to`,
      method: "get",
      params: data,
    })
  );
};

/*
 * @Author: likai 770151764@qq.com
 * @Date: 2022-08-29 18:44:25
 * @LastTime: 2023-04-19 09:51:37
 * @LastAuthor: liudanli 565478958@qq.com
 * @FilePath: \unicew-nuxt\utils\login.js
 * @Description: Do not Description
 */
import vue from "vue";
// facebook 登录整体对象
// let googleClient = null;
// googleClient 字段如果重新new此类 那么字段是空
export default class Login {
  // facebook对象
  FB = null;
  // google对象
  google = null;
  // google api
  gapi = null;
  googleClient = null;
  // nuxt对象
  dispatch = null;
  commit = null;
  name = null;
  thridConfig = {
    facebook: {
      data: {
        appId: "5906055762775953",
        status: false,
        cookie: true,
        localStorage: true,
        xfbml: true,
        frictionlessRequests: true,
        version: "v2.7",
      },
      initFun: this.initFacebook.bind(this),
    },
    google: {
      data: {
        client_id: "545251053475-kuf4fd8onng3j9ehpvjqki8t9uludsck.apps.googleusercontent.com",
        scope:
          "https://www.googleapis.com/auth/userinfo.profile \
        https://www.googleapis.com/auth/userinfo.email",
        callback: "",
      },
      initFun: this.initGoogle.bind(this),
    },
  };
  constructor(dispatch, commit) {
    if (window) {
      console.log(window, "<--------window", window.FB, window.google);
      if (!this.FB && window.FB) {
        console.log("window中有FB");
        this.FB = window.FB;
      }
      if (!this.google && window.google) {
        console.log("window中有Google");
        this.google = window.google;
      }
      if (!this.gapi && window.gapi) {
        console.log("window中有Gapi");
        this.gapi = window.gapi;
      }
    }
    // console.log(dispatch);
    // if (nuxtStore) {
    this.dispatch = dispatch;
    this.commit = commit;
    // }
  }
  // google facebook都调用init方法初始化数据
  init(type) {
    try {
      const config = this.thridConfig[type];
      config.initFun(config.data);
    } catch (error) {
      throw new Error("初始化第三方登录失败" + JSON.stringify(error));
    }
  }
  // 初始化facebook
  initFacebook(data) {
    if (this.FB) {
      console.log("初始化facebook登录 JavaScript SDK");
      this.FB.init(data);
    }
  }
  // 初始化google
  initGoogle(data) {
    if (this.google) {
      console.log("初始化Google登录 JavaScript SDK");
      const tokenClient = this.google.accounts.oauth2.initTokenClient(data);
      // this.commit("setLoginServiceGoogleClient", tokenClient);
      this.googleClient = tokenClient;
    }
    // else {
    //   vue.prototype.$toast("google failed to load");
    // }
  }
  initGoogleApi() {
    if (this.gapi) {
      console.log("进入gapi加载");
      this.gapi.load("client", this.intializeGapiClient.bind(this));
    } else {
      vue.prototype.$toast("google failed to load");
    }
  }
  //------------------facebook-----------------------//
  facebookCheckLogin() {
    if (this.FB) {
      this.FB.getLoginStatus(this.facebookStatusCheckCallBack.bind(this));
    } else {
      vue.prototype.$toast("facebook failed to load");
    }
  }
  facebookStatusCheckCallBack(response) {
    console.log("查询登录状态回调函数------->", response);
    if (response.status !== "connected") {
      this.facebookLogin();
    } else {
      this.getFacebookMe(response);
    }
  }
  // 获取facebook个人信息
  getFacebookMe(response) {
    this.FB.api("/me?fields=id,first_name,last_name,email,picture", (res) => {
      console.log(res, "调用业务接口进行创建账号");
      if (!res.error) {
        const { email, first_name, last_name, id } = res;
        const data = {
          email: email ? email : `${id}@facebook.com`,
          lastname: last_name,
          firstname: first_name,
          sns_login: "facebook",
          customer_source: 0,
          source_url: window.location.href,
        };
        this.dispatch("thridLoginInterface", { params: data, response: this.authResponse(response) });
        // this.thridLoginInterface();
      }
    });
  }
  facebookLogin() {
    const opt = {
      scope: "public_profile,email",
    };
    this.FB.login((response) => {
      console.log("登录完成状态回调函数------->", response);
      if (response.status === "connected") {
        this.getFacebookMe(response);
      }
    }, opt);
  }
  facebookLogout() {
    this.FB.logout((response) => {
      console.log(response, "大苏打实打实的");
    });
  }
  //------------------facebook-----------------------//
  //------------------google-----------------------//
  // google 登录函数处理
  googleClick() {
    // 获取google token的时候进行api初始化
    console.log(this.googleClient, "ddd");
    this.initGoogleApi();
    this.googleClient.requestAccessToken();
    this.googleClient.callback = this.hanlderGoogleCallBack.bind(this);
  }
  // 处理谷歌回调
  hanlderGoogleCallBack(response) {
    try {
      this.initGapi(response);
    } catch (err) {
      console.log(err);
    }
    // window.gapi.load("client", this.initGapi);
  }
  async intializeGapiClient() {
    this.gapi.client.init({
      apikey: "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",
      // apiKey: "AIzaSyArrLeDNwv4ZmQkQiRKpscRfXy-bM1wfNM",
      // discoveryDocs: [DISCOVERY_DOC],
    });
  }
  async initGapi(response) {
    try {
      // console.log(this.gapi.client.people.people);
      // const res = await this.gapi.client.people.people.get({
      //   resourceName: "people/me",
      //   personFields: "names,emailAddresses",
      // });
      const res = await this.gapi.client.request({
        path: "https://people.googleapis.com/v1/people/me?requestMask.includeField=person.names,person.emailAddresses",
      });
      console.log(res);
      const { result } = res;
      if (result) {
        if (!result.emailAddresses) {
          vue.prototype.$toast("Failed to obtain authorization, in order to obtain email");
          return false;
        }
        const data = {
          email: result.emailAddresses[0].value,
          lastname: result.names[0].familyName,
          firstname: result.names[0].givenName,
          sns_login: "google",
          customer_source: 0,
          source_url: window.location.href,
        };
        // this.thridLoginInterface(data, this.authResponse(response));
        this.dispatch("thridLoginInterface", { params: data, response: this.authResponse(response) });
      } else {
        console.log(res);
        vue.prototype.$toast("Failed to get google personal information");
      }
    } catch (error) {
      console.log(error, "<----------------------错误");
    }
  }
  googleLogout(token) {
    this.google.accounts.oauth2.revoke(token);
  }
  //------------------google-----------------------//
  authResponse(response) {
    return response.authResponse || response;
  }
}

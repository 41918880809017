import { render, staticRenderFns } from "./userlayout.vue?vue&type=template&id=11a611dc&scoped=true&"
import script from "./userlayout.vue?vue&type=script&lang=js&"
export * from "./userlayout.vue?vue&type=script&lang=js&"
import style0 from "./userlayout.vue?vue&type=style&index=0&id=11a611dc&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11a611dc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeadAd: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pagePC/components/HeadAd.vue').default,HeadNav: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pagePC/components/HeadNav.vue').default,UserNav: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pagePC/components/UserNav.vue').default,Footer: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pagePC/components/Footer.vue').default})

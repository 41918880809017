/*
 * @Author: mengllingshuai menglingshuai@busybiz.cn
 * @Date: 2022-06-08 11:38:07
 * @LastTime: 2023-06-13 13:37:26
 * @LastAuthor: liudanli 565478958@qq.com
 * @FilePath: \unicew-nuxt\api\baseApi.js
 * @Description: Do not Description
 */
export default ({ $axios }, inject) => {
  // 根据url查询类型
  inject("convertUrl", (data) =>
    $axios({
      url: `api/common/convert_url`,
      method: "get",
      params: data,
    })
  );
  // 获取动态路由列表
  inject("getUrlList", (data) =>
    $axios({
      url: `api/common/urls`,
      method: "get",
      params: data,
    })
  );
  // 获取服务器时间
  inject("serverTime", (data) =>
    $axios({
      url: `api/common/server_time`,
      method: "get",
      params: data,
    })
  );
  // 获取物流是否存在
  inject("getTrackExist", (data) =>
    $axios({
      url: `api/common/track_info`,
      method: "get",
      params: data,
    })
  );
  // 获取邮箱是否存在
  inject("getEmailExists", (data) =>
    $axios({
      url: `api/common/email`,
      method: "get",
      params: data,
    })
  );
  /**
   * 历史记录
   * @Date: 2022-09-21 11:17:10
   * @Description: Do not Description
   * @return {*}
   */
  inject("getProductByIds", (data) =>
    $axios({
      url: `api/common/product_list`,
      method: "get",
      params: data,
    })
  );
  /**
   * 上传文件
   * @Date: 2022-07-18 16:00:32
   * @Description: Do not Description
   * @param {FormData} data
   * @return {*}
   */
  inject("upload", (data) =>
    $axios({
      url: `api/common/upload`,
      method: "post",
      data,
    })
  );
  inject("getSystemConfigInfo", (data) =>
    $axios({
      url: `api/common/system_configs`,
      method: "get",
      params: data,
    })
  );
  // 通用根据api获取数据
  inject("getApiInfo", (data) =>
    $axios({
      url: "api/common/api",
      method: "get",
      params: data,
    })
  );
  // 刷新token
  inject("refreshToken", (data) =>
    $axios({
      url: "api/auth/refresh",
      method: "get",
      params: data,
    })
  );
  // 推荐商品
  inject("alsoLike", (data) =>
    $axios({
      url: "api/common/also_like",
      method: "get",
      params: data,
    })
  );
  // 获取颜色列表
  inject("commonColor", (data) =>
    $axios({
      url: "/api/common/color",
      method: "get",
      params: data,
    })
  );
  // // 同步老站购物车
  // inject("pullQuote", (data) =>
  //   $axios({
  //     url: `api/common/pull_quote`,
  //     method: "post",
  //     data,
  //   })
  // );
  //广告
  inject("addAds", (data) =>
    $axios({
      url: `/api/wides`,
      method: "get",
      params: data,
    })
  );
  //弹框管理
  inject("popoupManage", (data) =>
    $axios({
      url: `/api/common/popup`,
      method: "get",
      params: data,
    })
  );
  //site-map
  inject("initSiteMap", () =>
    $axios({
      url: `/api/common/sitemap`,
      method: "get",
    })
  );

  //查询物流
  inject("getTrackInfo", (data) =>
    $axios({
      url: `/api/tracks`,
      method: "get",
      params: data,
    })
  );
  // 查询订单物流没数据时 催发货
  inject("secondaryUrgingTrack", (data) =>
    $axios({
      url: `/api/order/secondary/urging_track/${data.id}`,
      method: "post",
    })
  );
  // 装修
  inject("getDiyTemplate", (data) =>
    $axios({
      url: `/api/diy_template/${data.id}`,
      method: "get",
    })
  );
  // 获取meta 配置
  inject("getMetaInfo", (data) =>
    $axios({
      url: `/api/seo`,
      method: "get",
      params: data,
    })
  );
  // 获取自定义表单
  inject("getFormDiyInfo", (data) =>
    $axios({
      url: `/api/surveys`,
      method: "get",
      params: data,
    })
  );
  // 提交自定义表单
  inject("submitFormDiyInfo", (data) =>
    $axios({
      url: `/api/surveys`,
      method: "post",
      data,
    })
  );
  // 邮件埋点
  inject("emailEvents", (data) =>
    $axios({
      url: `/api/email_events`,
      method: "get",
      params: data,
    })
  );

  // 顶部订阅
  inject("newsletter", (data) =>
    $axios({
      url: `api/auth/newsletter`,
      method: "post",
      data,
    })
  );
};

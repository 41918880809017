export default ({ $axios }, inject) => {
  // 获取照片墙列表
  inject("customerImageList", (data) =>
    $axios({
      url: `/api/customer_images`,
      method: "get",
      params: data,
    })
  );
  // 添加照片墙
  inject("addCustomerImage", (data) =>
    $axios({
      url: `/api/customer_images`,
      method: "post",
      data,
    })
  );
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SlideUpDown from "vue-slide-up-down";
export default {
  components: { SlideUpDown },
  props: {},
  data() {
    return {
      userNav: [
        {
          title: "My Assets",
          itemlist: [
            {
              title: "points",
              link: "/user/points",
            },
            {
              title: "coupon",
              link: "/user/coupon",
            },
            {
              title: "wishlist",
              link: "/user/wishlist",
            },
          ],
          isShow: true,
        },
        {
          title: "order center",
          itemlist: [
            {
              title: "My Orders",
              link: "/user/order",
            },
          ],
          isShow: true,
        },
        {
          title: "Account Setting",
          itemlist: [
            {
              title: "Personal Information",
              link: "/user/setting/info",
            },
            {
              title: "change password",
              link: "/user/setting/password",
            },
            {
              title: "address book",
              link: "/user/address",
            },
          ],
          isShow: true,
        },
        {
          title: "More Service",
          itemlist: [
            // {
            //   title: "faq",
            //   link: "/faq",
            // },
            {
              title: "blogs",
              link: "/blog",
            },
          ],
          isShow: true,
        },
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {
    handleShow(item) {
      item.isShow = !item.isShow;
    },
  },
  created() {},
  mounted() {},
};

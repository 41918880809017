/*
 * @Author: mengllingshuai menglingshuai@busybiz.cn
 * @Date: 2022-06-28 10:06:47
 * @LastTime: 2023-03-14 16:30:00
 * @LastAuthor: liguohua 2490322461@qq.com
 * @FilePath: \unicew-nuxt\api\addressApi.js
 * @Description: Do not Description
 */
export default ({ $axios }, inject) => {
  // 获取国家列表
  inject("countriesList", (data) =>
    $axios({
      url: `/api/common/countries`,
      method: "get",
      params: data,
    })
  );
  // 获取地区列表
  inject("regionsList", (data) =>
    $axios({
      url: `/api/common/regions`,
      method: "get",
      params: data,
    })
  );

  // 地址列表（结算页）
  inject("confirmOrderAddressList", () =>
    $axios({
      url: `/api/address`,
      method: "GET",
    })
  );
  // 保存账单地址（结算页）
  inject("saveConfirmOrderBilling", (data) =>
    $axios({
      url: `/api/quote/billing/address`,
      method: "post",
      data,
    })
  );
  // 保存收货地址（结算页）
  inject("saveConfirmOrderShipping", (data) =>
    $axios({
      url: `/api/quote/shipping/address`,
      method: "post",
      data,
    })
  );
  // 地址列表
  inject("addressList", (data) =>
    $axios({
      url: `/api/customer_address`,
      method: "get",
      params: data,
    })
  );
  //确认shipping地址 (支付成功后确认)
  inject("confirmShippingAddress", (data) =>
    $axios({
      url: `/api/confirm_shipping_address`,
      method: "post",
      data,
    })
  );
  // 修改shipping地址 (支付成功后修改)
  inject("updateShippingAddress", (data) =>
    $axios({
      url: `/api/update_shipping_address`,
      method: "post",
      data,
    })
  );
  // 删除地址
  inject("deleteShippingAddress", (data) =>
    $axios({
      url: `/api/customer_address/${data.id}`,
      method: "DELETE",
    })
  );
  // 地址详情 个人中心
  inject("addressDetail", (data) =>
    $axios({
      url: `/api/customer_address/${data.id}`,
      method: "get",
      params: data,
    })
  );

  // 地址新建  个人中心
  inject("createAddress", (data) =>
    $axios({
      url: `/api/customer_address`,
      method: "post",
      data,
    })
  );

  // 地址更新 shiping 个人中心
  inject("updataAddress", (data, id) =>
    $axios({
      url: `/api/customer_address/${id}`,
      method: "put",
      data,
    })
  );
};

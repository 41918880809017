/*
 * @Author: liudanli 565478958@qq.com
 * @Date: 2023-02-11 09:43:12
 * @LastTime: 2023-04-25 16:44:12
 * @LastAuthor: liudanli 565478958@qq.com
 * @FilePath: \unicew-nuxt\middleware\auth.js
 * @Description: Do not Description
 */
export default function ({ app, store, redirect, route }) {
  // let isClient = process.client;
  // let isServer = process.server;
  let userInfo = app.$cookies.get("userInfo");
  // console.log(store.state.user.userInfo);
  // console.log(route);
  let topath = route.path;
  if (userInfo) {
    // console.log(userInfo);
    //登陆状态
    store.commit("user/setIsLogin", true);
    if (route.name === "login") {
      redirect("/user");
    }
  } else {
    //未登陆
    if (topath.indexOf("user") != -1) {
      redirect("/login");
    }
  }
  // route.beforeEach((to, from, next) => {
  //   if (to.name === from.name) {
  //     const error = new Error('NavigationDuplicated');

  //     error.name = 'NavigationDuplicated';

  //     next(error)
  //   } else {
  //     next()
  //   }
  // })
}

/*
 * @Author: liguohua 2490322461@qq.com
 * @Date: 2023-03-22 13:36:24
 * @LastAuthor: liguohua 2490322461@qq.com
 * @LastTime: 2023-03-22 14:21:32
 * @FilePath: \unicew-nuxt\plugins\videoPlayer.js
 * @Description: Do not Description
 */
import Vue from "vue";
import VideoPlayer from "vue-video-player";
// require('video.js/dist/video-js.css')  //按官网引会找不到然后报错，换成下面那个
import "video.js/dist/video-js.css";
// import 'vue-video-player/src/custom-theme.css'
export default () => {
  Vue.use(VideoPlayer);
};

/*
 * @Author: mengllingshuai menglingshuai@busybiz.cn
 * @Date: 2022-12-12 16:51:14
 * @LastTime: 2023-05-22 08:32:32
 * @LastAuthor: liudanli 565478958@qq.com
 * @FilePath: \unicew-nuxt\plugins\pageSeo.js
 * @Description: Do not Description
 */

export default async ({ app, store }) => {
  // store.commit("setSeoInfo", {
  //  403: { title: "403, Jurllyshe" },
  // 404: { title: "Sorry this is 404" },
  // });
  let { code, data } = await app.$getMetaInfo();
  if (code == 200000) {
    let tempData = {
      // 403: { title: "403, Jurllyshe" },
      // 404: { title: "Sorry this is 404" },
      // cart: { title: "Cart Jurllyshe" },
      // forgotPassword: { title: "Forgot Password" },
      // login: { title: "Login" },
      // monitor: { title: "Monitor, Jurllyshe" },
      // sizeGide: { title: "Size Gide, Jurllyshe" },
      // styleGallery: { title: "Style Gallery, Jurllyshe" },
      // user: { title: "User, Jurllyshe" },
      // "address/book": { title: "Address Book, Jurllyshe" },
      // "address/save": { title: "Address Save, Jurllyshe" },
      // "order/address": { title: "Order Address, Jurllyshe" },
      // "order/confirm": { title: "Order Confirm, Jurllyshe" },
      // "order/payment": { title: "Order Payment, Jurllyshe" },
      // "questionAnswer/detail": { title: "Question Answer Detail, Jurllyshe" },
      // "questionAnswer/list": { title: "Question Answer List, Jurllyshe" },
      // "reviews/list": { title: "Reviews List, Jurllyshe" },
      // "user/coupons": { title: "User Coupons, Jurllyshe" },
      // "user/feedback": { title: "User Feedback, Jurllyshe" },
      // "user/forgetPassword": { title: "User Forget Password, Jurllyshe" },
      // "user/logout": { title: "User Logout, Jurllyshe" },
      // "user/order": { title: "User Order, Jurllyshe" },
      // "user/point": { title: "User Point, Jurllyshe" },
      // "user/setting": { title: "User Setting, Jurllyshe" },
      // "user/wishlist": { title: "User Wishlist, Jurllyshe" },
      // "user/components/AdsCenter": { title: "User Components AdsCenter, Jurllyshe" },
      // "user/components/OrderUserCenter": { title: "User Components OrderUserCenter, Jurllyshe" },
      // "user/components/ProductTempate": { title: "User Components ProductTempate, Jurllyshe" },
      // "user/components/ReviewOrderProduct": { title: "User Components ReviewOrderProduct, Jurllyshe" },
      // "user/components/UserMenu": { title: "User Components UserMenu, Jurllyshe" },
      // "user/components/UserStatusBar": { title: "User Components UserStatusBar, Jurllyshe" },
      // "user/point/details": { title: "User Point Details, Jurllyshe" },
      // "user/point/program": { title: "User Point Program, Jurllyshe" },
      // "user/setting/info": { title: "User Setting Info, Jurllyshe" },
      // "user/setting/password": { title: "User Setting Password, Jurllyshe" },
      // "vip/components/faq": { title: "Vip Components Faq, Jurllyshe" },
      // vip: { title: "Vip , Jurllyshe" },
      // "vip/rules": { title: "Vip Rules, Jurllyshe" },
      // "user/order/detail*": { title: "User Order detail*, Jurllyshe" },
      // "user/order/logistics*": { title: "User Order Logistics, Jurllyshe" },
      // "user/order/review*": { title: "User Order Review, Jurllyshe" },
      // "user/order/unpaid*": { title: "User Order Unpaid, Jurllyshe" },
      // "user/order/viewReview*": { title: "User Order ViewReview, Jurllyshe" },
    };
    data.seo.forEach((item) => {
      tempData[item.url] = { title: item.title };
    });

    store.commit("setSeoInfo", tempData);
  }
};

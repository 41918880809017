/*
 * @Description: Do not Description
 * @Author: liudanli 565478958@qq.com
 * @Date: 2023-03-18 08:29:19
 * @LastTime: 2023-04-28 14:08:30
 * @LastAuthor: liudanli 565478958@qq.com
 * @FilePath: \unicew-nuxt\layouts\activeDialog.js
 */
export default {
  data() {
    return {
      // popoupData: null,
      newCustomerData: null,
    };
  },
  created() {
    // 实例被创建之后执行代码
    // if(process.client){
    //   console.log('在客户端');
    // }
    // if(process.server){
    //   console.log('在服务端');
    // }
  },
  methods: {
    // /**
    //  * @description: 用于操作弹框的关闭和打开
    //  * @return {*}
    //  * @Author: liudanli
    //  * @Date: 2023-03-24 15:53:04
    //  * @LastTime: Do not edit
    //  * @LastAuthor: liudanli丶
    //  * @Description: Do not Description
    //  */
    // newCustomerDialog() {
    //   console.log(this.$refs);
    //   console.log(this.$refs.newCustomer);
    //   this.$refs.newCustomer.changeDialog();
    // },
    // handleUpdata(data) {
    //   this.newCustomerData = data;
    // },
  },
  computed: {},
  mounted() {},
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    // 父辈向子辈传参
  },
  name: "HeadSearch",
  data() {
    return {
      searchContent: "",
      searchFlage: false,
      historyList: [], //搜索历史
      suggestList: [],
      hotList: [], //热搜词
      showkeyword: false,
      showSuggestStaus: false,
    };
  },
  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
  },
  components: {
    // 组件的引用
  },
  methods: {
    // 方法
    useHistory(val) {
      this.searchContent = val;
      this.changeUrl(val);
      this.googleCustomeEventHanlder(val, "history_search", "side_menu");
    },
    changeUrl(val) {
      this.$router.push({
        path: "/search/result",
        query: { q: val },
      });
    },
    /**
     * @Author: liudanli 565478958@qq.com
     * @Date: 2023-06-01 13:54:25
     * @LastAuthor: liudanli 565478958@qq.com
     * @description: 移除历史记录
     * @return {*}
     * @Description: Do not Description
     */
    removeHistory() {
      this.historyList = [];
      localStorage.removeItem("getCategory");
      this.googleCustomeEventHanlder("delete", "history_search", "side_menu");
    },
    /**
     * @Author: liudanli 565478958@qq.com
     * @Date: 2023-06-01 11:54:16
     * @LastAuthor: liudanli 565478958@qq.com
     * @description:  获取热搜
     *
     * @return {*}
     * @Description: Do not Description
     */
    async getEsShow() {
      try {
        let { code, data } = await this.$getSystemConfigInfo({
          module: "system_configs",
        });
        if (code == 200000) {
          this.hotList = data.searchPageHotKeywords;
        }
      } catch (error) {
        throw new Error("获取热搜词" + error);
      }
    },
    /**
     * @Author: liudanli 565478958@qq.com
     * @Date: 2023-06-01 13:39:34
     * @LastAuthor: liudanli 565478958@qq.com
     * @description: 获取搜索历史
     * @return {*}
     * @Description: Do not Description
     */
    getHistory() {
      this.historyList = JSON.parse(localStorage.getItem("getCategory")) || [];
      // console.log(this.historyList, "搜索历史");
    },
    showSearch() {
      this.searchFlage = !this.searchFlage;
      this.showkeyword = !this.showkeyword;
      this.showSuggestStaus = !this.showSuggestStaus;
    },
    /**
     * @Author: liudanli 565478958@qq.com
     * @Date: 2023-05-22 14:57:45
     * @LastAuthor: liudanli 565478958@qq.com
     * @description:
     * @return {*}
     * @Description: Do not Description
     */
    handleInputFocus() {
      this.showSuggestStaus = true;
      this.showkeyword = true;
    },
    async suggestEnterHanlder() {
      try {
        let { code, data } = await this.$getEsSuggest({
          suggest: this.searchContent,
        });
        if (code == 200000) {
          this.suggestList = data.suggests;
        }
      } catch (error) {
        throw new Error("关联词搜索联想" + error);
      }
    },
    /**
     * @Author: liudanli 565478958@qq.com
     * @Date: 2023-06-01 14:49:12
     * @LastAuthor: liudanli 565478958@qq.com
     * @description: 关键词提交搜索
     * @param {*} item
     * @return {*}
     * @Description: Do not Description
     */
    clickSuggestHandle(item) {
      this.searchContent = item;
      this.handlerSearch();
      this.searchFlage = false;
      this.showkeyword = false;
      this.showSuggestStaus = false;
    },
    /**
     * @Author: liudanli 565478958@qq.com
     * @Date: 2023-06-01 14:51:23
     * @LastAuthor: liudanli 565478958@qq.com
     * @description: enter 键 提交搜索
     * @return {*}
     * @Description: Do not Description
     */
    handlerSearch() {
      try {
        if (this.searchContent != "") {
          console.log("验证通过");
          this.historyList.unshift(this.searchContent);
          this.historyList = Array.from(new Set(this.historyList));
          localStorage.setItem("getCategory", JSON.stringify(this.historyList.slice(0, 10)));
          this.changeUrl(this.searchContent);
          this.googleCustomeEventHanlder(this.searchContent, "search", "side_menu");
        } else {
          // console.log(this.$validator.errors.all());
          // console.log(this.$validator.errors);
        }
      } catch (error) {
        throw new Error("搜索处理函数" + error);
      }
    },
  },
  mounted() {
    // 页面进入时加载内容
    this.getEsShow();
    this.getHistory();
  },
  watch: {
    // 监测变化
    searchContent: {
      handler() {
        this.suggestEnterHanlder();
      },
    },
  },
};

/*
 * @Author: likai 770151764@qq.com
 * @Date: 2022-09-01 17:54:49
 * @LastTime: 2022-10-10 17:28:13
 * @LastAuthor: mengllingshuai menglingshuai@busybiz.cn
 * @FilePath: /jurllyshe-nuxt/plugins/login.js
 * @Description: 初始化login
 */

export default () => {
  // if (process.client) {
  //   store.dispatch("user/initThridLogin");
  // }
  document.addEventListener("click", (event) => {
    // event.preventDefault();
    // console.log(event, "点击元素");
    window.handlerTrack = function () {
      return event;
    };
  });
};

/*
 * @Author: hepengqiang
 * @Date: 2024-03-26 14:05:31
 * @LastEditors: hepengqiang &&  435257119@qq.com
 * @LastEditTime: 2024-05-09 15:04:09
 * @FilePath: \bf-nuxte:\unicew-nuxt\api\activity.js
 * @Description: file content
 * @LastAuthor: hepengqiang
 * @LastTime: Do not edit
 */
export default ({ $axios }, inject) => {
  // 线上线下也页面配置信息
  inject("getShopLine", () =>
    $axios({
      url: `/api/activity/openingstore`,
      method: "get",
    })
  );
  // 线上线下店提交表单
  inject("setOpeningstore", (data) =>
    $axios({
      url: `/api/activity/openingstorestore`,
      method: "post",
      data,
    })
  );
  // 新客页面
  inject("getCustomer", () =>
    $axios({
      url: `/api/act/new_customer_0319`,
      method: "get",
    })
  );
  // 新客页面 领取优惠券
  // 普通领取优惠券
  inject("getfreeCoupon", (params) =>
    $axios({
      url: `/api/new_customer_0319_coupon_receive`,
      method: "post",
      params,
    })
  );

  // 新客页面 优惠券信息
  inject("getCouponConfig", () =>
    $axios({
      url: `/api/common/new_user_coupon_detail`,
      method: "get",
    })
  );
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    // 父辈向子辈传参
    name: {
      type: String,
      require: true,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      require: true,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: [String, Number],
    iclass: {
      type: String,
      default: "",
    },
    validate: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
    requireStar() {
      if (this.validate != "") {
        return true;
      }
      return false;
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },

  components: {
    // 组件的引用
  },
  methods: {
    // 方法
    handleInput(event) {
      this.$emit("input", event.target.value);
    },
    handleFocus() {
      this.$emit("input-focus");
    },
  },
  mounted() {
    // 页面进入时加载内容
  },
  watch: {
    // 监测变化
  },
};

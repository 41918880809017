/*
 * @Author: mengllingshuai menglingshuai@busybiz.cn
 * @Date: 2022-07-01 15:25:22
 * @LastTime: 2023-04-29 16:58:29
 * @LastAuthor: liudanli 565478958@qq.com
 * @FilePath: \unicew-nuxt\plugins\ga.js
 * @Description: Do not Description
 */
if (process.client) {
  //   //添加 gtm 相关代码
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "GTM-TGQ9HRR");
  window.dataLayer = window.dataLayer || [];
}
export default ({ app: { store } }, inject) => {
  let currency = store.getters["user/currency"]?.symbol || "$";
  //   if (process.client && process.env.NODE_ENV === 'production') {
  if (process.client) {
    //图文统一处理 点击和浏览
    inject("googleGraphicEvent", (param) => {
      if (param.event_category == "all" && store.getters["pageType"]) {
        param.event_category = store.getters["pageType"];
      }
      if (param.eventType == "click") {
        param.event_action = "promoClick";
        eventPromotion(param);
      } else if (param.eventType == "view") {
        param.event_action = "promoView";
        eventPromotion(param, "dle_view_promotion");
      }
    });

    //自定义事件处理 例如 点击导航图标等
    inject("googleCustomeEvent", (param) => {
      if (param.dlv_ec == "all" && store.getters["pageType"]) {
        param.dlv_ec = store.getters["pageType"];
      }
      console.log(param);
      let dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: "dle_custom_event",
        dlv_en: `${param.dlv_en}`,
        dlv_ec: `${param.dlv_ec}`, // 该参数如和页面加载时推送的公共参数page_group一致，此处可不进行推送
        dlv_ea: `${param.dlv_ea}`,
        dlv_el: `${param.dlv_el}`,
      });
    });

    //产品列表曝光
    inject("googleCategroyListView", (param) => {
      param.currency = currency;
      param.event_action = "productView";
      if (param.event_category == "all" && store.getters["pageType"]) {
        param.event_category = store.getters["pageType"];
      }
      eventCategroyList(param, "dle_view_item_list");
    });
    //产品列表点击
    inject("googleCategroyListClick", (param) => {
      param.currency = currency;
      param.event_action = "productClick";
      if (param.event_category == "all" && store.getters["pageType"]) {
        param.event_category = store.getters["pageType"];
      }
      eventCategroyList(param);
    });

    // view_item 产品详情曝光 进入详情页
    inject("googleGoodDetailView", (param) => {
      param.event_category = "productDetail";
      param.event_action = "pageLoad";
      param.event_label = "product_detail";
      let dataLayer = window.dataLayer || [];
      dataLayer.push({ ecommerce: null });
      dataLayer.push({
        event: "dle_view_item",
        dlv_ec: `${param.event_category}`,
        dlv_ea: `${param.event_action}`,
        dlv_el: `${param.event_label}`,
        dlv_cu: `${currency}`,
        dlv_val: `${param.value}`,
        ecommerce: {
          items: [
            {
              item_name: `${param.item_name}`,
              item_id: `${param.item_id}`,
              item_category: `${param.item_category}`,
              price: `${param.price}`,
              currency: `${currency}`,
            },
          ],
        },
      });
      // console.log(param);
    });

    // add_to_wishlist 加入收藏 或者 移除收藏
    inject("googleActionWishlist", (param) => {
      if (param.eventType == "add") {
        param.event = "dle_add_to_wishlist";
      } else if (param.eventType == "remove") {
        param.event = "dle_remove_from_wishlist";
      }
      let dataLayer = window.dataLayer || [];
      dataLayer.push({ ecommerce: null });
      dataLayer.push({
        event: param.event,
        dlv_ec: `${param.event_category}`,
        dlv_ea: `Click`,
        dlv_el: `${param.event_label}`,
        dlv_cu: `${currency}`,
        dlv_val: `${param.value}`,
        ecommerce: {
          items: [
            {
              item_name: `${param.item_name}`,
              item_id: `${param.item_id}`,
              price: `${param.price}`,
              item_category: `${param.item_category}`,
              item_list_name: `${param.item_list_name}`,
              index: `${param.index}`,
              quantity: `${param.quantity}`,
              currency: `${currency}`,
            },
          ],
        },
      });

      console.log(param);
    });

    // 产品操作相关 例如 加入购物车 移除 点击加减号等
    inject("googleProductActionCart", (param) => {
      if (param.eventType == "add") {
        param.event = "dle_add_to_cart";
      } else if (param.eventType == "remove") {
        param.event = "dle_remove_from_cart";
      }
      let dataLayer = window.dataLayer || [];
      dataLayer.push({ ecommerce: null });
      dataLayer.push({
        event: param.event,
        dlv_ec: `${param.event_category}`,
        dlv_ea: `${param.event_action}`,
        dlv_el: `${param.event_label}`,
        dlv_cu: `${currency}`,
        dlv_val: `${param.value}`,
        ecommerce: {
          items: [
            {
              item_name: `${param.item_name}`,
              item_id: `${param.item_id}`,
              price: `${param.price}`,
              item_category: `${param.item_category}`,
              quantity: `${param.quantity}`,
              currency: `${currency}`,
            },
          ],
        },
      });
      console.log(param);
    });

    // begin_checkout 开始结账
    inject("googleBeginCheckout", (param) => {
      let dataLayer = window.dataLayer || [];
      let productItems = param.items;
      productItems = productItems.map((item, index) => {
        return {
          item_name: item.name,
          item_id: item.productId,
          item_category: item?.categories ? item?.categories[0].id : 0,
          price: item.previewFinalPriceFormat,
          quantity: item.qty,
          index: index,
          currency: currency,
        };
      });
      console.log(productItems);
      dataLayer.push({ ecommerce: null });
      dataLayer.push({
        event: param.event,
        dlv_ec: `${param.event_category}`,
        dlv_ea: `${param.event_action}`,
        dlv_el: `${param.event_label}`,
        dlv_cu: `${currency}`,
        dlv_val: `${param.value}`,
        ecommerce: {
          items: productItems,
        },
      });
    });

    // add_shipping_info 添加配送信息
    inject("googleAddShippingInfo", (param) => {
      let dataLayer = window.dataLayer || [];
      let productItems = param.items;
      productItems = productItems.map((item, index) => {
        return {
          item_name: item.name,
          item_id: item.productId,
          item_category: item?.categories ? item?.categories[0].id : 0,
          price: item.previewFinalPriceFormat,
          quantity: item.qty,
          index: index,
          currency: currency,
        };
      });
      console.log(productItems);
      dataLayer.push({ ecommerce: null });
      dataLayer.push({
        event: "dle_add_shipping_info",
        dlv_ec: `${param.event_category}`,
        dlv_ea: `${param.event_action}`,
        dlv_el: `${param.event_label}`,
        dlv_cu: `${currency}`,
        dlv_val: `${param.value}`,
        shipping_tier: `${param.shipping_tier}`,
        ecommerce: {
          items: productItems,
        },
      });
    });

    // add_payment_info 添加付款信息
    inject("googleAddPaymentInfo", (param) => {
      let dataLayer = window.dataLayer || [];
      let productItems = param.items;
      productItems = productItems.map((item, index) => {
        return {
          item_name: item.name,
          item_id: item.productId,
          item_category: item?.categories ? item?.categories[0].id : 0,
          price: item.previewFinalPriceFormat,
          quantity: item.qty,
          index: index,
          currency: currency,
        };
      });
      console.log(productItems);
      dataLayer.push({ ecommerce: null });
      dataLayer.push({
        event: "dle_add_payment_info",
        dlv_ec: `${param.event_category}`,
        dlv_ea: `${param.event_action}`,
        dlv_el: `${param.event_label}`,
        payment_type: `${param.payment_type}`,
        ecommerce: {
          currency: `${currency}`,
          value: `${param.value}`,
          payment_type: `${param.payment_type}`,
          items: productItems,
        },
      });
    });

    // purchase 完成购买
    inject("googlePurchase", (param) => {
      let dataLayer = window.dataLayer || [];
      let productItems = param.items;
      productItems = productItems.map((item, index) => {
        return {
          item_name: item.name,
          item_id: item.productId,
          item_category: item?.categories ? item?.categories[0].id : 0,
          price: item.priceFormat,
          quantity: item.qtyOrdered,
          index: index,
          currency: currency,
        };
      });
      console.log(productItems);
      dataLayer.push({ ecommerce: null });
      dataLayer.push({
        event: "dle_purchase",
        dlv_ec: `paidSuccess`,
        dlv_ea: `productPurchase`,
        dlv_el: `transaction_success`,
        dlv_cu: `${param.currency}`,
        dlv_val: `${param.value}`,
        transaction_id: `${param.transaction_id}`,
        shipping: `${param.shipping}`,
        coupon: `${param.coupon}`,
        tax: `${param.tax}`,
        ecommerce: { items: productItems },
      });
    });
  }
};

/**
 * @Author: liguohua 2490322461@qq.com
 * @Date: 2023-04-13 17:24:00
 * @LastAuthor: liguohua 2490322461@qq.com
 * @description: 内部推广活动 曝光 点击
 * @param {*} param
 * @param {*} type dle_view_promotion,dle_select_promotion
 * @return {*}
 * @Description: Do not Description
 */
function eventPromotion(param, type = "dle_select_promotion") {
  console.log(param);
  let data = {
    event_category: param.event_category || "",
    event_action: param.event_action || "",
    event_label: param.event_label || "",
    item_name: param.promotion_name || "",
    item_id: param.promotion_id || "",
    promotion_id: param.promotion_id || "",
    promotion_name: param.promotion_name || "",
    creative_name: param.creative_name || "",
    creative_slot: param.creative_slot || "",
  };
  let dataLayer = window.dataLayer || [];
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: type,
    dlv_ec: `${data.event_category}`,
    dlv_ea: `${data.event_action}`,
    dlv_el: `${data.event_label}`,
    ecommerce: {
      items: [
        {
          item_name: `${data.item_name}`,
          item_id: `${data.item_id}`,
          promotion_id: `${data.promotion_id}`,
          promotion_name: `${data.promotion_name}`,
          creative_name: `${data.creative_name}`,
          creative_slot: `${data.creative_slot}`,
        },
      ],
    },
  });
  // console.log(data);
}

/**
 * @Author: liguohua 2490322461@qq.com
 * @Date: 2023-04-13 17:24:00
 * @LastAuthor: liguohua 2490322461@qq.com
 * @description: 产品列表 曝光 点击
 * @param {*} param
 * @param {*} type dle_view_item_list,dle_select_item
 * @return {*}
 * @Description: Do not Description
 */
function eventCategroyList(param, type = "dle_select_item") {
  let data = param;
  let dataLayer = window.dataLayer || [];
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: type,
    dlv_ec: `${data.event_category}`,
    dlv_ea: `${data.event_action}`,
    dlv_el: `${data.event_label}`,
    ecommerce: {
      items: [
        {
          item_name: `${data.item_name}`,
          item_id: `${data.item_id}`,
          price: `${data.price}`,
          item_category: `${data.item_category}`,
          item_list_name: `${data.item_list_name}`,
          index: `${data.index}`,
          currency: `${data.currency}`,
        },
      ],
    },
  });
  // console.log(data);
}

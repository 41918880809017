/*
 * @Author: mengllingshuai menglingshuai@busybiz.cn
 * @Date: 2022-06-17 19:14:03
 * @LastTime: 2022-09-17 16:25:11
 * @LastAuthor: mengllingshuai menglingshuai@busybiz.cn
 * @FilePath: /jurllyshe-nuxt/api/cartApi.js
 * @Description: Do not Description
 */
export default ({ $axios }, inject) => {
  // 获取购物车列表
  inject("getCartList", (data) =>
    $axios({
      url: `/api/quote`,
      method: "get",
      params: data,
    })
  );
  // 添加购物车产品
  inject("addCart", (data) =>
    $axios({
      url: `/api/quote`,
      method: "post",
      data,
    })
  );
  // 删除购物车产品
  inject("deleteCart", (data) =>
    $axios({
      url: `/api/quote/${data.id}`,
      method: "delete",
      data,
    })
  );
  // 修改购物车产品
  inject("updateCart", (data) =>
    $axios({
      url: `/api/quote/${data.id}`,
      method: "put",
      data,
    })
  );
  // 删除无效的商品
  inject("deleteInvalidProduct", (data) =>
    $axios({
      url: `/api/quote/clear/${data.id}`,
      method: "POST",
      data,
    })
  );
  // 捆绑商品添加购物车
  inject("quoteBatch", (data) =>
    $axios({
      url: `/api/quote/batch`,
      method: "POST",
      data,
    })
  );
  // 选中部分购物车
  inject("quoteAttach", (data) =>
    $axios({
      url: `/api/quote/attach`,
      method: "POST",
      data,
    })
  );
  // 取消选中部分购物车
  inject("quoteDetach", (data) =>
    $axios({
      url: `/api/quote/detach`,
      method: "POST",
      data,
    })
  );
};

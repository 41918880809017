/*
 * @Description: 用于活动的接口存放
 * @Author: liudanli 565478958@qq.com
 * @Date: 2023-03-24 15:56:21
 * @LastTime: 2023-03-24 17:39:47
 * @LastAuthor: liudanli 565478958@qq.com
 * @FilePath: \unicew-nuxt\api\activeDialogApi.js
 */
export default ({ $axios }, inject) => {
  //新客弹框
  inject("newUserCoupon", () =>
    $axios({
      url: `api/coupon/receive/new_user_coupon`,
      method: "post",
    })
  );
};

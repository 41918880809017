/*
 * @Author: mengllingshuai menglingshuai@busybiz.cn
 * @Date: 2022-07-01 15:25:22
 * @LastTime: 2023-05-20 12:20:44
 * @LastAuthor: liudanli 565478958@qq.com
 * @FilePath: \unicew-nuxt\plugins\tool.js
 * @Description: 全局工具函数
 */
import Vue from "vue";
import lodash from "lodash";
import CryptoJS from "crypto-js";
//
const tool = {
  eventBus: new Vue(),
  // 变量格式转换 item_id => itemId
  camelCaseMult(data, type = "camelCase") {
    if (typeof data !== "object" || !data) {
      return data;
    }
    if (Array.isArray(data)) {
      return data.map((item) => this.camelCaseMult(item, type));
    }
    const newData = {};
    for (const key in data) {
      const newKey = lodash[type](key);
      newData[newKey] = this.camelCaseMult(data[key], type);
    }
    return newData;
  },
  camelCaseStr(data) {
    if (typeof data !== "string") {
      return data;
    } else {
      return lodash.camelCase(data);
    }
  },
  // 校验邮箱
  isEmail(str) {
    str = str.trim();
    let reg = /^\w+([-+.!%#$&*_]\w+)*@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/;
    return reg.test(str);
  },
  // 校验密码长度
  isPassword(str) {
    return str.length >= 8;
  },
  // 验证手机号码不包含特殊符号
  isPhone(num) {
    let reg = /^[^\\?@`*]$/;
    return reg.test(num);
  },
  // 加密
  encrypt(text) {
    const str = CryptoJS.enc.Utf8.parse(text);
    const _key = CryptoJS.enc.Utf8.parse(process.env.ENCRYPT_KEY);
    const _iv = CryptoJS.enc.Utf8.parse(process.env.ENCRYPT_IV);
    var encrypted = CryptoJS.AES.encrypt(str, _key, {
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    let res = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    return res;
  },
  // 解密
  decrypt(text) {
    let baseResult = CryptoJS.enc.Base64.parse(text); // Base64解密
    let ciphertext = CryptoJS.enc.Base64.stringify(baseResult); // Base64解密
    const _key = CryptoJS.enc.Utf8.parse(process.env.ENCRYPT_KEY);
    const _iv = CryptoJS.enc.Utf8.parse(process.env.ENCRYPT_IV);
    let decryptResult = CryptoJS.AES.decrypt(ciphertext, _key, {
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    let resData = decryptResult.toString(CryptoJS.enc.Utf8).toString();
    return resData;
  },
  /**
   * @Date: 2022-07-09 09:25:27
   * @Description: Do not Description
   * @param {leftTime：number}  时间差
   * @param {interval:1000} interval 倒计时间隔单位：ms 默认：1000
   * @param isShowDay 是否显示天
   * @return {h,m,s,ms}
   */
  countDown(leftTime, interval = 1000, callback, isShowDay = false) {
    let d;
    let h;
    let m;
    let s;
    let ms;
    if (leftTime >= 0) {
      if (isShowDay) {
        d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
        m = Math.floor((leftTime / 1000 / 60) % 60);
        s = Math.floor((leftTime / 1000) % 60);
        ms = Math.floor(leftTime % 1000);
      } else {
        h = Math.floor(leftTime / 1000 / 60 / 60);
        m = Math.floor((leftTime / 1000 / 60) % 60);
        s = Math.floor((leftTime / 1000) % 60);
        ms = Math.floor(leftTime % 1000);
      }
      if (ms < 10) {
        ms = "0" + ms;
      }
      if (s < 10) {
        s = "0" + s;
      }
      if (m < 10) {
        m = "0" + m;
      }
      if (h < 10) {
        h = "0" + h;
      }
      if (d < 10) {
        d = "0" + d;
      }
      let time1 = setTimeout(() => {
        leftTime -= interval;
        this.countDown(leftTime, interval, callback, isShowDay);
      }, interval);
      callback({ d, h, m, s, ms }, time1, leftTime);
    } else {
      return;
    }
  },
  //px转rem 结合postcss 插件使用
  pxTorem(pxNum) {
    if (pxNum) {
      let remRes;
      if (window["lib"].flexible.rem >= 54) {
        remRes = window["lib"].flexible.px2rem(pxNum / (750 / 540));
      } else {
        let tempRem = window["lib"].flexible.rem * 10;
        remRes = window["lib"].flexible.px2rem(pxNum / (750 / tempRem));
      }
      return remRes.toFixed(2);
    } else {
      return 0;
    }
  },
  reachBottom(scrollNum = 0, callback) {
    if (process.client) {
      // this.$nextTick(() => {
      window.onscroll = function () {
        //变量scrollTop是滚动条滚动时，距离顶部的距离
        var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        // console.log(scrollTop)
        //变量windowHeight是可视区的高度
        var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
        //变量scrollHeight是滚动条的总高度
        var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        //滚动条到底部的条件
        if (scrollTop + windowHeight >= scrollHeight - scrollNum) {
          callback();
        }
      };
      // });
    }
  },
  /**
   * @Date:
   * @Description: 滚动底部可用于区域滚动 监听占位元素是否在可视区域内
   * @param {ele：element}  放在区域底部的占位元素
   */
  reachBottomEle(ele, callback) {
    let ioReachBot = new IntersectionObserver(
      function (entries) {
        // 如果不可见，就返回
        if (entries[0].intersectionRatio <= 0) return;
        //callback
        callback();
      },
      {
        threshold: 0.1,
      }
    );
    ioReachBot.observe(document.querySelector(ele));
  },
  /**
   * @Date:
   * @Description: copy
   * @param {message} 用于复制的文案
   */
  copyMessage(message) {
    var input = document.createElement("input");
    input.value = message;
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, input.value.length), document.execCommand("Copy");
    document.body.removeChild(input);
  },
  getLocalTime(i = -7) {
    //参数i为时区值数字，比如北京为东八区则输进8,西5输入-5
    if (typeof i !== "number") return;
    var d = new Date();
    //得到1970年一月一日到现在的秒数
    var len = d.getTime();
    //本地时间与GMT时间的时间偏移差
    var offset = d.getTimezoneOffset() * 60000;
    //得到现在的格林尼治时间
    var utcTime = len + offset;
    return new Date(utcTime + 3600000 * i);
  },
  //设置LocalStorage 及过期时间
  setLocalStorage(key, value, day = 1) {
    if (process.client) {
      // 设置过期原则
      if (!value) {
        localStorage.removeItem(key);
      } else {
        let exp = new Date();
        if (Array.isArray(value)) {
          localStorage[key] = JSON.stringify({
            value: value,
            expires: exp.getTime() + day * 24 * 60 * 60 * 1000,
          });
        } else {
          localStorage[key] = JSON.stringify({
            value: value,
            expires: exp.getTime() + day * 24 * 60 * 60 * 1000,
          });
        }
      }
    }
  },
  //获取LocalStorage过期时间
  getLocalStorage(key) {
    if (process.client) {
      try {
        var o = JSON.parse(localStorage[key]);
        if (!o || o.expires < Date.now()) {
          return false;
        } else {
          return o.value;
        }
      } catch (e) {
        if (localStorage.getItem(key) == null) {
          return undefined;
        } else {
          return localStorage[key];
        }
      }
    }
  },

  //随机数
  getMathRandom(max, min) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  },
  // 浏览器 信息
  BrowserInfo() {
    let Sys = {};
    let ua = navigator.userAgent.toLowerCase();
    let isChrome = ua.match(/chrome\/([\d.]+)/);
    let isSafari = ua.match(/version\/([\d.]+).*safari/);
    let isEdge = ua.match(/edge\/([\d.]+)/);
    let isFirefox = ua.match(/firefox\/([\d.]+)/);
    let isOpera = ua.match(/opera.([\d.]+)/);
    // let isChrome = console.log(254, ua);
    switch (true) {
      case isChrome?.length > 0:
        Sys.name = "Chrome";
        Sys.version = isChrome[1];
        break;
      case isSafari?.length > 0:
        Sys.name = "Safari";
        Sys.version = isSafari[1];
        break;
      case isEdge?.length > 0:
        Sys.name = "Edge";
        Sys.version = isEdge[1];
        break;
      case isFirefox?.length > 0:
        Sys.name = "Firefox";
        Sys.version = isFirefox[1];
        break;
      case isOpera?.length > 0:
        Sys.name = "Opera";
        Sys.version = isOpera[1];
        break;
      default:
        break;
    }
    return Sys;
  },
  // 首字母转大写
  firstToUpper(str) {
    return str.trim().toLowerCase().replace(str[0], str[0].toUpperCase());
  },
  // 判断元素是否在可视区域内，只有当子元素全部出现在父元素中时，才会返回true。
  isContain(dom) {
    const totalHeight = window.innerHeight || document.documentElement.clientHeight;
    const totalWidth = window.innerWidth || document.documentElement.clientWidth;
    // 当滚动条滚动时，top, left, bottom, right时刻会发生改变。
    const { top, right, bottom, left } = dom.getBoundingClientRect();
    // console.log(top, right, bottom, left)
    return top >= 0 && left >= 0 && right <= totalWidth && bottom <= totalHeight;
  },
  /**
   * @Author: mengllingshuai menglingshuai@busybiz.cn
   * @Date: 2023-02-24 11:36:36
   * @LastTime: Do not edit
   * @LastAuthor: mengllingshuai menglingshuai@busybiz.cn
   * @Description: 弹窗内有滚动区域时，下层页面
   * @return {*}
   */
  isFiexdBody(showDialog) {
    let bodyStyle = document.body.style;
    if (showDialog) {
      let scrollTop = document.documentElement.scrollTop;
      if (scrollTop) {
        bodyStyle.position = "fixed";
        bodyStyle.top = `-${scrollTop}px`;
      }
    } else {
      let scrollToNum = bodyStyle.top.replace(/[^0-9]/gi, "");
      bodyStyle.position = "";
      bodyStyle.top = ``;
      window.scrollTo({ top: scrollToNum });
    }
  },
};
// C端错误的提示语
const ErrTip = {
  emailTip: "Please enter a valid email address. For example johndoe@velvethairextension",
  phoneTip: "format error",
  agreeClause: "please agree clause",
};
Vue.prototype.tool = tool;
Vue.prototype.ErrTip = ErrTip;
export default ({ app }) => {
  app.tool = tool;
};

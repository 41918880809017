/*
 * @Author: likai 770151764@qq.com
 * @Date: 2022-07-05 17:19:33
 * @LastTime: 2023-03-13 14:02:09
 * @LastAuthor: liguohua 2490322461@qq.com
 * @FilePath: \unicew-nuxt\api\reviewApi.js
 * @Description: Do not Description
 */
export default ({ $axios }, inject) => {
  //新增评论
  inject("creatReview", (data) =>
    $axios({
      url: `/api/reviews`,
      method: "post",
      data,
    })
  );
  inject("getReviewDetailByOrderId", (orderId) =>
    $axios({
      url: `/api/reviews/${orderId}`,
      method: "get",
    })
  );
  //评论列表
  inject("reviewList", (data) =>
    $axios({
      url: `/api/reviews`,
      method: "get",
      params: data,
    })
  );

  //评论点赞
  inject("reviewItemLike", (reviewItemId) =>
    $axios({
      url: `/api/reviews/like/${reviewItemId}`,
      method: "post",
    })
  );
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ["error"],
  layout: "404", // 你可以为错误页面指定自定义的布局
};

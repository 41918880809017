/*
 * @Description: Do not Description
 * @Author: liudanli 565478958@qq.com
 * @Date: 2023-03-28 13:35:08
 * @LastTime: 2023-04-11 10:44:01
 * @LastAuthor: liguohua 2490322461@qq.com
 * @FilePath: \unicew-nuxt\api\blogApi.js
 */
export default ({ $axios }, inject) => {
  // 博客列表
  inject("blogList", (data) =>
    $axios({
      url: `/api/blogs`,
      method: "get",
      params: data,
    })
  );
  // 博客分类接口
  inject("blogCategories", (data) =>
    $axios({
      url: `api/blog_categories`,
      method: "get",
      params: data,
    })
  );
  // 博客详情
  inject("getBlogDetail", (data) =>
    $axios({
      url: `api/blogs/${data.id}`,
      method: "get",
    })
  );
  // 博客新增评论
  inject("blogCreat", (data) =>
    $axios({
      url: `/api/blog_comments`,
      method: "post",
      data,
    })
  );
  // 博客分享
  inject("blogShare", (id) =>
    $axios({
      url: `/api/blog_share/${id}`,
      method: "post",
    })
  );

  // cms单页
  inject("cmsPageInfo", (data) =>
    $axios({
      url: `/api/cms_pages/${data.id}`,
      method: "get",
      params: data,
    })
  );
};

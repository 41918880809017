/*
 * @Author: mengllingshuai menglingshuai@busybiz.cn
 * @Date: 2022-07-04 16:17:38
 * @LastTime: 2023-01-28 11:21:30
 * @LastAuthor: mengllingshuai menglingshuai@busybiz.cn
 * @FilePath: /jurllyshe-nuxt/api/orderApi.js
 * @Description: Do not Description
 */

export default ({ $axios }, inject) => {
  inject("orderList", (data) =>
    $axios({
      url: "api/orders",
      method: "get",
      params: data,
    })
  );
  inject("orderDetail", (id) =>
    $axios({
      url: `api/orders/${id}`,
      method: "get",
    })
  );
  // 创建订单
  inject("createOrder", (data) =>
    $axios({
      url: `api/orders/create`,
      method: "get",
      params: data,
    })
  );
  // 支付订单
  inject("payOrder", (data) =>
    $axios({
      url: `api/orders`,
      method: "post",
      data,
    })
  );
  // 支付方式列表
  inject("payMethods", (data) =>
    $axios({
      url: `api/common/pay_methods`,
      method: "get",
      params: data,
    })
  );
  // 运输方式
  inject("shippingMethods", (data) =>
    $axios({
      url: `api/common/shipping_methods`,
      method: "get",
      params: data,
    })
  );
  // 确认订单获取优惠券列表
  inject("quoteCoupons", (data) =>
    $axios({
      url: `api/customer/quote_coupons`,
      method: "get",
      params: data,
    })
  );

  // 确认订单使用优惠券
  inject("applyCoupon", (data) =>
    $axios({
      url: `api/orders/apply_coupon`,
      method: "post",
      data,
    })
  );

  // 取消订单使用优惠券
  inject("cancelCoupon", (data) =>
    $axios({
      url: `api/orders/cancel_coupon`,
      method: "post",
      data,
    })
  );
  //获取/adyen/create 的支付方式
  inject("getAdyenPayMethods", (data) =>
    $axios({
      url: `api/adyen/create`,
      method: "post",
      params: data,
    })
  );
  // 支付成功回调
  inject("payCallBack", (data) =>
    $axios({
      url: `api/pay/return`,
      method: "post",
      data,
    })
  );
  // 获取stripe client_secret
  inject("getStripeClientSecret", (data) =>
    $axios({
      url: `api/stripe/create`,
      method: "post",
      data,
    })
  );
  //重新支付
  inject("payReplay", (data) =>
    $axios({
      url: `api/pay/replay`,
      method: "post",
      data,
    })
  );
  //取消订单
  inject("ordersCancel", (data) =>
    $axios({
      url: `api/orders/cancel/${data.id}`,
      method: "post",
      data,
    })
  );
  //获取运费险
  inject("shippingGuarantee", () =>
    $axios({
      url: `api/common/shipping_guarantee`,
      method: "get",
    })
  );
  // 抵达支付成功页面触发接口，标记这个订单已经抵达支付成功。
  inject("arrivePaySuccess", (data) =>
    $axios({
      url: `api/order/secondary/arrive_pay_success/${data.id}`,
      method: "post",
      data,
    })
  );

  //全局优惠券
  inject("orderScopeAllCoupons", () =>
    $axios({
      url: `api/orders/scope_all_coupons`,
      method: "get",
    })
  );
};

/*
 * @Author: liudanli 565478958@qq.com
 * @Date: 2023-03-30 14:39:56
 * @LastAuthor: liudanli 565478958@qq.com
 * @LastTime: 2023-03-30 14:43:13
 * @FilePath: \unicew-nuxt\store\blog.js
 * @Description: Do not Description
 */
export const state = () => ({
  blogCatId: null,
  blogInfo: null,
  cmsPageId: null,
});
export const getters = {
  blogCatId(state) {
    return state.blogCatId;
  },
  blogInfo(state) {
    return state.blogInfo;
  },
  cmsPageId(state) {
    return state.cmsPageId;
  },
};
export const mutations = {
  setBlogCatId(state, data) {
    state.blogCatId = data;
  },
  setBlogInfo(state, data) {
    state.blogInfo = data;
  },
  setCmsPageId(state, data) {
    state.cmsPageId = data;
  },
};

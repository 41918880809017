/*
 * @Author: mengllingshuai menglingshuai@example.com
 * @Date: 2022-07-01 15:25:22
 * @LastTime: 2023-05-19 17:21:33
 * @LastAuthor: liudanli 565478958@qq.com
 * @FilePath: \unicew-nuxt\plugins\mixins\global.js
 * @Description: 全局minix
 */
export default {
  computed: {
    currencySymbol() {
      let symbol;
      let cookiesSymbol = this.$cookies.get("currencySymbol");
      let storeSymbol = this.$store.getters["user/currency"].symbol;
      if (cookiesSymbol) {
        symbol = cookiesSymbol;
      } else {
        symbol = storeSymbol;
      }
      return symbol;
    },
  },
  filters: {
    formatPrice(item) {
      return (item * 1).toFixed(2);
    },
  },
  methods: {
    // 跳转
    handleJump(url, isWindow = false) {
      if (!url) {
        return;
      }
      const reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
      if (reg.test(url)) {
        if (isWindow) {
          window.open(url);
          return;
        }
        window.location.href = url;
        return;
      }
      this.$router.push(url);
    },
    goBack(val = 1) {
      console.log(37, val, window.history, document.referrer);
      // return;
      // 1. 三方平台跳转返回
      // let isToHome = document.referrer.length > 0 && !document.referrer.includes("jurllyshe") && !document.referrer.includes("localhost");
      // if (isToHome) {
      //   this.$router.replace({
      //     path: "/",
      //   });
      // } else {
      //   this.$router.back(val);
      // }
      this.$router.back(val);
    },
    //拼接图片链接
    connectImgUrl(url, size, imgQuality = 80) {
      if (!url) {
        console.log(imgQuality, "imgQuality");
        return;
      }
      let imgUrl = `${process.env.IMG_URL}/${url}`;
      if (size) {
        let w = size.split("x")[0];
        let h = size.split("x")[1];
        // imgUrl = `${imgUrl}?x-oss-process=image/resize,w_${w},h_${h}/quality,q_${imgQuality}`;
        imgUrl = `${imgUrl}?im=Resize,width=${w},height=${h}`;
        // imgUrl = `${process.env.IMG_URL}/fit-in/${size}/filters:quality(${imgQuality})/${url}`;
      }
      return imgUrl;
    },

    // 添加gtm 数据统计
    addGtmData() {},

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-04-14 15:18:16
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: datalayer push
     * @param {*} type
     * @return {*}
     * @Description: Do not Description
     */
    googleCustomeEventHanlder(type, eventAction = "click", eventName = "top_nav", event_category = this.$route.name) {
      this.$googleCustomeEvent({
        event: "dle_custom_event",
        dlv_en: eventName,
        dlv_ec: event_category,
        dlv_ea: eventAction,
        dlv_el: type,
      });
    },

    // 判断元素是否在可视区域内，只有当子元素全部出现在父元素中时，才会返回true。
    isContain(dom) {
      const totalHeight = window.innerHeight || document.documentElement.clientHeight;
      const totalWidth = window.innerWidth || document.documentElement.clientWidth;
      // 当滚动条滚动时，top, left, bottom, right时刻会发生改变。
      const { top, right, bottom, left } = dom.getBoundingClientRect();
      // console.log(top, right, bottom, left)
      return top >= 0 && left >= 0 && right <= totalWidth && bottom <= totalHeight;
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-02-03 13:14:18
     * @LastTime: Do not edit
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 可视区触发
     * @param {*} eles 触发的对象
     * @return {*}
     */
    proxyGtmContainImage(eles) {
      // console.log("滚动触发");
      if (process.client) {
        try {
          eles.forEach((item) => {
            if (this.isContain(item)) {
              // console.log(item);
              if (item.getAttribute("data-datalayerLoaded") == 1) {
                return false;
              }
              if (item.getAttribute("class").indexOf("dataLayerTriggerEvent") > -1) {
                // 图片push
                let param = {};
                param = {
                  eventType: "view",
                  event_category: this.$route.name,
                  event_label: item.getAttribute("data-label"),
                  promotion_name: item.getAttribute("data-creative_name"),
                  promotion_id: item.getAttribute("data-promotion_id"),
                  creative_name: item.getAttribute("data-creative_name"),
                  creative_slot: item.getAttribute("data-solt"),
                };
                this.$googleGraphicEvent(param);
              } else if (item.getAttribute("class").indexOf("dataLayerTriggerProduct") > -1) {
                let param = {};
                param = {
                  event_category: item.getAttribute("data-event_category"),
                  event_label: item.getAttribute("data-event_label"),
                  item_name: item.getAttribute("data-item_name"),
                  item_id: item.getAttribute("data-id"),
                  price: item.getAttribute("data-price"),
                  item_category: item.getAttribute("data-item_category"),
                  item_list_name: item.getAttribute("data-item_list_name"),
                  index: item.getAttribute("data-index"),
                };
                this.$googleCategroyListView(param);
              }

              //添加一个变量属性 data-datalayerLoaded 用于记录是否已经浏览过 不让重复 push
              item.setAttribute("data-datalayerLoaded", 1);
            }
          });
        } catch (error) {
          throw new Error("Datalayer img 可视区域 事件埋点报错：", error);
        }
      }
    },

    //ga图文浏览事件
    googleViewEventHandler(e) {
      console.log(e.target);
    },
  },
};

import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=099aa0ee&scoped=true&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"
import style0 from "./Footer.vue?vue&type=style&index=0&id=099aa0ee&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "099aa0ee",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Input: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pagePC/components/Design/Input.vue').default,Footer: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pagePC/components/Footer.vue').default})

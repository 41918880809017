/*
 * @Author: mengllingshuai menglingshuai@busybiz.cn
 * @Date: 2022-07-13 08:47:25
 * @LastTime: 2023-06-15 18:04:11
 * @LastAuthor: liudanli 565478958@qq.com
 * @FilePath: \unicew-nuxt\api\categoryApi.js
 * @Description: Do not Description
 */
// 栏目列表
export default ({ $axios }, inject) => {
  //pc导航
  inject("navData", () =>
    $axios({
      url: `api/navigations`,
      method: "get",
    })
  );
  inject("categoriesList", (data) =>
    $axios({
      url: `api/categories`,
      method: "get",
      params: data,
    })
  );
  //栏目详情
  inject("categoriesDetail", (data) =>
    $axios({
      url: `api/categories/${data}`,
      method: "get",
    })
  );
  //货币列表
  inject("getCurrency", (data) =>
    $axios({
      url: `api/common/currency`,
      method: "get",
      params: data,
    })
  );
  // 栏目广告图&热搜词
  inject("getEsShow", () =>
    $axios({
      url: `api/product_es_show`,
      method: "get",
    })
  );
  // 搜索商品
  inject("getEsSearch", (params) =>
    $axios({
      url: `api/product_es_search`,
      method: "get",
      params,
    })
  );
  // 无搜索结果产品集合
  inject("getEsRecommend", (params) =>
    $axios({
      url: `api/product_es_recommend`,
      method: "get",
      params,
    })
  );
  // 关联搜索
  inject("getEsSuggest", (params) =>
    $axios({
      url: `api/product_es_suggest`,
      method: "get",
      params,
    })
  );

  //列表页注册优惠券
  inject("getListReigsterCoupon", (params) =>
    $axios({
      url: `/api/list_register/coupon`,
      method: "get",
      params,
    })
  );
  // 列表页注册成功优惠券
  inject("receiveListReigsterSuccessCoupon", () =>
    $axios({
      url: `/api/coupon/receive/list_register_success`,
      method: "post",
    })
  );

  // 获取商品属性option
  inject("getProductOption", (attribute) =>
    $axios({
      url: `/api/products/attribute/option/${attribute}`,
      method: "get",
    })
  );
  // 获取商品product type
  inject("getProductType", (data) =>
    $axios({
      url: `/api/products/attribute/option`,
      method: "get",
      params: data,
    })
  );
};

import { render, staticRenderFns } from "./default.vue?vue&type=template&id=966f1a0c&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeadAd: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pagePC/components/HeadAd.vue').default,HeadNav: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pagePC/components/HeadNav.vue').default,Footer: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pagePC/components/Footer.vue').default})

/*
 * @Author: mengllingshuai menglingshuai@busybiz.cn
 * @Date: 2022-07-20 11:15:06
 * @LastTime: 2023-04-27 19:07:26
 * @LastAuthor: liudanli 565478958@qq.com
 * @FilePath: \unicew-nuxt\plugins\apmError.js
 * @Description: Do not Description
 */
import Vue from "vue";
import { ApmVuePlugin } from "@elastic/apm-rum-vue";
export default () => {
  Vue.use(ApmVuePlugin, {
    config: {
      serverUrl: "https://apm.africanmall.com",
      serviceName: "Velvethair-nuxt",
      usePathAsTransactionName: true,
      environment: "live",
    },
  });
};

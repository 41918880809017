/*
 * @Author: mengllingshuai menglingshuai@example.com
 * @Date: 2022-07-01 17:38:56
 * @LastTime: 2023-06-08 17:08:39
 * @LastAuthor: liudanli 565478958@qq.com
 * @FilePath: \unicew-nuxt\plugins\axios.js
 * @Description: Do not Description
 */
import { v4 as uuidv4 } from "uuid";
// let isNewUser = null;
export default ({ app, $axios, store, redirect, error, route }) => {
  $axios.defaults.baseURL = process.env.BASE_URL;
  $axios.defaults.timeout = 1000 * 10;
  $axios.defaults.withCredentials = process.env.NODE_ENV !== "development";

  let noUseTokenApi = ["api/auth/login"];
  // let noShowLoadingApi = ["api/quote"];
  // 请求拦截
  $axios.onRequest((config) => {
    // StoreId 移动端就固定是2 PC固定是1
    config.headers["StoreId"] = process.env.PAGE_NAME == "pc" ? "1" : "2"; //注释，因为本地pc端没有数据
    // config.headers["StoreId"] = 2;
    if (process.server) {
      config.headers["user-agent"] = "LqPassWaf/851.3";
    }
    if (process.client) {
      store._vm.$nextTick(() => {
        if (store._vm.$nuxt) {
          store._vm.$nuxt.$loading.start();
        }
      });
    }
    // console.log(config);
    if (!noUseTokenApi.includes(config.url)) {
      // 本项目token 存储在cookies里面, 游客暂时使用 LQ-Token
      let { authorization } = app.$cookies.get("userInfo") || { authorization: "visitor" };
      if (authorization) {
        config.headers.Authorization = authorization;
      }
    }
    // if (process.client && isNewUser == null) {
    //   isNewUser = app.$cookies.get("userInfo") ? false : true;
    //   sessionStorage.setItem("isNewUser", isNewUser);
    // }
    let isLogin = app.$cookies.get("userInfo") ? true : false;
    store.commit("user/setIsLogin", isLogin);

    let guestToken = app.$cookies.get("guestToken");
    if (!guestToken) {
      // 游客生成 guestToken
      let uuidStr = uuidv4();
      let tempObj = { timestamp: Date.now(), token: uuidStr.replace(/-/g, "") };
      store.commit("setGuestToken", app.tool.encrypt(JSON.stringify(tempObj)));
      app.$cookies.set("guestToken", app.tool.encrypt(JSON.stringify(tempObj)));
      config.headers["LQ-Token"] = app.$cookies.get("guestToken") || store.getters["guestToken"];
    } else {
      config.headers["LQ-Token"] = app.$cookies.get("guestToken") || store.getters["guestToken"];
    }
    config.headers["Currency"] = route?.query?.currency || app.$cookies.get("currency") || store.getters["user/currency"].currencyTo;
  });

  // 服务器返回异常拦截
  $axios.onError((err) => {
    console.log(err, "接口err");
    if (process.client) {
      store._vm.$nextTick(() => {
        if (store?._vm.$nuxt) {
          store._vm.$nuxt.$loading.finish();
        }
      });
    }
    if (err && err.message == "timeout of 10000ms exceeded") {
      app.$toast("Network error, please try again");
      redirect("/");
    } else {
      throw new Error("服务器返回异常err" + JSON.stringify(err));
    }
    if (process.client) {
      // app.$toast("Oops...Something went wrong");
    }
    if (!error.response) {
      return;
    }
    if (error?.response.status == 404 || error?.response.status == 500) {
      if (process.client) {
        redirect("/404?url=" + window.location.href + "&apiInfo=" + JSON.stringify(err));
      } else {
        redirect("/404?apiInfo=" + JSON.stringify(err));
      }
      return;
    } else if (error?.response.status == 403) {
      redirect("/403");
      return;
    } else if (error) {
      console.log("服务器返回异常", error);
      throw new Error("服务器返回异常error" + JSON.stringify(error));
    }
    return;
  });

  // 接口数据返回拦截
  $axios.onResponse((response) => {
    if (process.client) {
      store._vm.$nextTick(() => {
        if (store?._vm.$nuxt) {
          store._vm.$nuxt.$loading.finish();
        }
      });
    }
    if (response.status == 200) {
      if (response.data.code == 200000) {
        return app.tool.camelCaseMult(response.data);
      } else if (response.data.code == 200101) {
        store.dispatch("user/removeLogin");
        redirect("/login");
      } else if (response.data.code == 200422) {
        if (process.client) {
          app.$toast({ type: "fail", content: response.data.message });
        }
      } else {
        app.$toast(response.data.message);
        return;
      }
      return;
    } else if (response.status == 404) {
      redirect("/404");
      return;
    } else {
      return;
    }
  });
};

/*
 * @Author: liudanli 565478958@qq.com
 * @Date: 2023-05-31 09:27:57
 * @LastAuthor: liudanli 565478958@qq.com
 * @LastTime: 2023-05-31 09:28:36
 * @FilePath: \unicew-nuxt\pagePC\components\Toast\index.js
 * @Description: Do not Description
 */
import Vue from "vue";
import toast from "./toast.vue";
// 创建组件构造器
const toastHonor = Vue.extend(toast);
function showToast(toastInfo) {
  if (process.client) {
    let text;
    let time = 2000;
    let iconName = undefined;
    if (typeof toastInfo == "string") {
      text = toastInfo;
    } else if (typeof toastInfo == "object") {
      switch (toastInfo.type) {
        case "success":
          iconName = "icon-check_fill";
          break;
        case "fail":
          iconName = "icon-close";
          break;
        case "warning":
          iconName = "icon-tishi";
          break;
      }
      text = toastInfo.content;
      // iconName = toastInfo.iconName;
      time = toastInfo.time || 2000;
    }
    // { text = "要显示的内容", time = 2000, type = undefined } = {}

    return new Promise((reslove) => {
      let toastComponent = new toastHonor({ el: document.createElement("div") });
      toastComponent.text = text;
      toastComponent.time = time;
      toastComponent.iconName = iconName;

      //插入到body
      document.body.appendChild(toastComponent.$el);
      // 回调函数
      toastComponent.close = function () {
        reslove();
      };
    });
  }
}
export default showToast;

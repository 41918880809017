//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import HeadSearch from "./HeadSearch.vue";
export default {
  components: { HeadSearch },
  props: {
    // 父辈向子辈传参
  },
  data() {
    return {
      // menuList: [],
      selectIndex: 0,
      isDropdownOpen: false,
      selectCategoryId: null,
      isFixed: false,
      ishide: false,
      isShowAccount: false,
      dropdownText: null,
    };
  },
  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
    ...mapGetters(["getNavigationData"]),
    ...mapGetters("user", ["isLogin"]),
    ...mapGetters("cart", ["cartTotal"]),

    menuList() {
      if (this.getNavigationData?.length > 0) {
        return this.getNavigationData;
      }
      return [];
    },
  },
  methods: {
    showAccount() {
      this.isShowAccount = !this.isShowAccount;
    },
    handlerTo(url) {
      this.$router.push({
        path: `${encodeURIComponent(url)}`,
      });
    },
    handleEnter(index) {
      this.selectIndex = index;
      // this.toggleDropdown(index);
    },
    // toggleDropdown(index) {
    //   if (index != 0) {
    //     this.isDropdownOpen = true;
    //   } else {
    //     this.isDropdownOpen = false;
    //   }
    // },
    handleLeave() {
      this.isDropdownOpen = false;
    },
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      // 判断是否滚动到导航栏顶部
      if (scrollTop > 0) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
    goCart() {
      this.$router.push({
        path: "/cart",
      });
    },
    handlerJum() {
      window.location.href = "https://www.unice.com/";
    },
    isActive(item) {
      console.log(item, this.$route);
      //  return ($route.path.replace('.html').toLowerCase()).indexOf(item.name.toLowerCase()) != -1
    },
  },
  mounted() {
    // 页面进入时加载内容
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {},
};

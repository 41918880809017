/*
 * @Author: mengllingshuai menglingshuai@busybiz.cn
 * @Date: 2022-10-08 19:55:09
 * @LastTime: 2022-12-20 16:12:11
 * @LastAuthor: mengllingshuai menglingshuai@busybiz.cn
 * @FilePath: /jurllyshe-nuxt/plugins/mixins/index.js
 * @Description: Do not Description
 */
import Vue from "vue";
import global from "./global";
const mixinsData = { global };
Object.keys(mixinsData).forEach((key) => {
  //每当向应用程序添加全局mixin时，都应使用一个标志以避免多次注册它
  if (!Vue[`__my_${key}__`]) {
    Vue[`__my_${key}__`] = true;
    Vue.mixin(mixinsData[key]);
  }
});

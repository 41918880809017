/*
 * @Author: liguohua 2490322461@qq.com
 * @Date: 2023-02-10 17:22:41
 * @LastAuthor: liguohua 2490322461@qq.com
 * @LastTime: 2023-03-03 14:48:51
 * @FilePath: \unicew-nuxt\utils\index.js
 * @Description: Do not Description
 */
export function debounce(callback, time) {
  // 防抖：防止抖动，单位时间内事件触发会被重置，避免事件被误伤触发多次。

  let timer = null;
  return (...args) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      callback.apply(this, args);
    }, time);
  };
}
export default function throttle(f, wait) {
  // 节流： 控制流量，单位时间内事件只能触发一次

  let timer;
  return (...args) => {
    if (timer) {
      return;
    }
    timer = setTimeout(() => {
      f(...args);
      timer = null;
    }, wait);
  };
}

import vue from "vue";
export function loadJsAsync(src, async, options) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    if (async != "") {
      script.async = async;
    }
    script.defer = "defer";
    if (options) {
      for (const key in options) {
        script.setAttribute(key, options[key]);
      }
    }

    const onload = () => {
      console.info("js loaded: ", src);
      script.removeEventListener("load", onload);

      resolve();
    };

    script.addEventListener("load", onload);
    script.addEventListener("error", (err) => {
      script.removeEventListener("load", onload);
      console.error("loading js error: ", src, err);
      reject(new Error(`Failed to load ${src}`));
    });

    (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(script);
  });
}

export function copyFn(val) {
  // createElement() 方法通过指定名称创建一个元素
  var copyInput = document.createElement("input");
  //val是要复制的内容
  copyInput.setAttribute("value", val);
  document.body.appendChild(copyInput);
  copyInput.select();
  var copyed = document.execCommand("Copy");
  if (copyed) {
    vue.prototype.$toast("Copy Successfully");
    document.body.removeChild(copyInput);
  }
}

/**
 * element 表单多个验证不通过，滚动到验证提示的位置
 * { String } object 验证规则
 * 备注：
 *     1
 */
export function scrollToView(_this, object) {
  for (let i in object) {
    let dom = _this.$refs[i.field];
    if (Object.prototype.toString.call(dom) !== "[object Object]") {
      //这里是针对遍历的情况（多个输入框），取值为数组
      dom = dom[0];
    }
    //第一种方法（包含动画效果）
    dom.$el.scrollIntoView({
      //滚动到指定节点
      block: "center", //值有start,center,end，nearest，当前显示在视图区域中间
      behavior: "smooth", //值有auto、instant,smooth，缓动动画（当前是慢速的）
    });

    break; //因为我们只需要检测一项,所以就可以跳出循环了
  }
}

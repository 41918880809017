import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6f76ecd8 = () => interopDefault(import('../pagePC/pages/403.vue' /* webpackChunkName: "pages/403" */))
const _6f850459 = () => interopDefault(import('../pagePC/pages/404.vue' /* webpackChunkName: "pages/404" */))
const _5c951394 = () => interopDefault(import('../pagePC/pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _b4f8ccdc = () => interopDefault(import('../pagePC/pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _6d3f0a2d = () => interopDefault(import('../pagePC/pages/forgotpassword.vue' /* webpackChunkName: "pages/forgotpassword" */))
const _e09aa86c = () => interopDefault(import('../pagePC/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _077660d2 = () => interopDefault(import('../pagePC/pages/register.vue' /* webpackChunkName: "pages/register" */))
const _82f91f06 = () => interopDefault(import('../pagePC/pages/user/index.vue' /* webpackChunkName: "pages/user/index" */))
const _0a8ed5cc = () => interopDefault(import('../pagePC/pages/activity/new-customer.vue' /* webpackChunkName: "pages/activity/new-customer" */))
const _42cd3bc1 = () => interopDefault(import('../pagePC/pages/activity/velvet-hair-store-grand-opening-event.vue' /* webpackChunkName: "pages/activity/velvet-hair-store-grand-opening-event" */))
const _f88bb540 = () => interopDefault(import('../pagePC/pages/order/confirm.vue' /* webpackChunkName: "pages/order/confirm" */))
const _13625da6 = () => interopDefault(import('../pagePC/pages/order/payment.vue' /* webpackChunkName: "pages/order/payment" */))
const _49e75a5d = () => interopDefault(import('../pagePC/pages/order/payWait.vue' /* webpackChunkName: "pages/order/payWait" */))
const _ce2af776 = () => interopDefault(import('../pagePC/pages/search/result.vue' /* webpackChunkName: "pages/search/result" */))
const _29f1ad22 = () => interopDefault(import('../pagePC/pages/user/address/index.vue' /* webpackChunkName: "pages/user/address/index" */))
const _48bc8e4e = () => interopDefault(import('../pagePC/pages/user/coupon/index.vue' /* webpackChunkName: "pages/user/coupon/index" */))
const _cc81429c = () => interopDefault(import('../pagePC/pages/user/logout/index.vue' /* webpackChunkName: "pages/user/logout/index" */))
const _58a90acb = () => interopDefault(import('../pagePC/pages/user/points/index.vue' /* webpackChunkName: "pages/user/points/index" */))
const _83be7c26 = () => interopDefault(import('../pagePC/pages/user/wishlist/index.vue' /* webpackChunkName: "pages/user/wishlist/index" */))
const _b5863bea = () => interopDefault(import('../pagePC/pages/order/components/address.vue' /* webpackChunkName: "pages/order/components/address" */))
const _46b9b32e = () => interopDefault(import('../pagePC/pages/user/order/unpaid/index.vue' /* webpackChunkName: "pages/user/order/unpaid/index" */))
const _6caac3b2 = () => interopDefault(import('../pagePC/pages/user/setting/info.vue' /* webpackChunkName: "pages/user/setting/info" */))
const _543cc51f = () => interopDefault(import('../pagePC/pages/user/setting/password.vue' /* webpackChunkName: "pages/user/setting/password" */))
const _f8da07c8 = () => interopDefault(import('../pagePC/pages/user/address/components/AddAddress.vue' /* webpackChunkName: "pages/user/address/components/AddAddress" */))
const _3de3b182 = () => interopDefault(import('../pagePC/pages/user/setting/components/uploadAvatar.vue' /* webpackChunkName: "pages/user/setting/components/uploadAvatar" */))
const _df29cefc = () => interopDefault(import('../pagePC/pages/user/order/detail/_id.vue' /* webpackChunkName: "pages/user/order/detail/_id" */))
const _2ee00089 = () => interopDefault(import('../pagePC/pages/user/order/review/_id.vue' /* webpackChunkName: "pages/user/order/review/_id" */))
const _844c8972 = () => interopDefault(import('../pagePC/pages/blog/category/_name.vue' /* webpackChunkName: "pages/blog/category/_name" */))
const _16e05db4 = () => interopDefault(import('../pagePC/pages/user/order/_status/_id.vue' /* webpackChunkName: "pages/user/order/_status/_id" */))
const _2fbaeafc = () => interopDefault(import('../pagePC/pages/blog/_id.vue' /* webpackChunkName: "pages/blog/_id" */))
const _73e9ee9a = () => interopDefault(import('../pagePC/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7507de40 = () => interopDefault(import('../pagePC/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/403",
    component: _6f76ecd8,
    name: "403"
  }, {
    path: "/404",
    component: _6f850459,
    name: "404"
  }, {
    path: "/blog",
    component: _5c951394,
    name: "blog"
  }, {
    path: "/cart",
    component: _b4f8ccdc,
    name: "cart"
  }, {
    path: "/forgotpassword",
    component: _6d3f0a2d,
    name: "forgotpassword"
  }, {
    path: "/login",
    component: _e09aa86c,
    name: "login"
  }, {
    path: "/register",
    component: _077660d2,
    name: "register"
  }, {
    path: "/user",
    component: _82f91f06,
    name: "user"
  }, {
    path: "/activity/new-customer",
    component: _0a8ed5cc,
    name: "activity-new-customer"
  }, {
    path: "/activity/velvet-hair-store-grand-opening-event",
    component: _42cd3bc1,
    name: "activity-velvet-hair-store-grand-opening-event"
  }, {
    path: "/order/confirm",
    component: _f88bb540,
    name: "order-confirm"
  }, {
    path: "/order/payment",
    component: _13625da6,
    name: "order-payment"
  }, {
    path: "/order/payWait",
    component: _49e75a5d,
    name: "order-payWait"
  }, {
    path: "/search/result",
    component: _ce2af776,
    name: "search-result"
  }, {
    path: "/user/address",
    component: _29f1ad22,
    name: "user-address"
  }, {
    path: "/user/coupon",
    component: _48bc8e4e,
    name: "user-coupon"
  }, {
    path: "/user/logout",
    component: _cc81429c,
    name: "user-logout"
  }, {
    path: "/user/points",
    component: _58a90acb,
    name: "user-points"
  }, {
    path: "/user/wishlist",
    component: _83be7c26,
    name: "user-wishlist"
  }, {
    path: "/order/components/address",
    component: _b5863bea,
    name: "order-components-address"
  }, {
    path: "/user/order/unpaid",
    component: _46b9b32e,
    name: "user-order-unpaid"
  }, {
    path: "/user/setting/info",
    component: _6caac3b2,
    name: "user-setting-info"
  }, {
    path: "/user/setting/password",
    component: _543cc51f,
    name: "user-setting-password"
  }, {
    path: "/user/address/components/AddAddress",
    component: _f8da07c8,
    name: "user-address-components-AddAddress"
  }, {
    path: "/user/setting/components/uploadAvatar",
    component: _3de3b182,
    name: "user-setting-components-uploadAvatar"
  }, {
    path: "/user/order/detail/:id?",
    component: _df29cefc,
    name: "user-order-detail-id"
  }, {
    path: "/user/order/review/:id?",
    component: _2ee00089,
    name: "user-order-review-id"
  }, {
    path: "/blog/category/:name?",
    component: _844c8972,
    name: "blog-category-name"
  }, {
    path: "/user/order/:status?/:id?",
    component: _16e05db4,
    name: "user-order-status-id"
  }, {
    path: "/blog/:id",
    component: _2fbaeafc,
    name: "blog-id"
  }, {
    path: "/",
    component: _73e9ee9a,
    name: "index"
  }, {
    path: "/*",
    component: _7507de40,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

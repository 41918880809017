/*
 * @Author: liudanli 565478958@qq.com
 * @Date: 2023-02-16 18:39:01
 * @LastTime: 2023-04-14 14:10:24
 * @LastAuthor: liguohua 2490322461@qq.com
 * @FilePath: \unicew-nuxt\plugins\veevalidate.js
 * @Description: Do not Description
 */
import Vue from "vue";
import VeeValidate, { Validator } from "vee-validate";
// import {required, max, email,} from 'vee-validate/dist/rules';

const messagesCn = {
  after: (field, [target]) => ` ${field} Must be after ${target}`,
  alpha_dash: (field) => ` ${field} Can contain alphanumeric characters, including dashes and underscores`,
  alpha_num: (field) => `${field} Can only contain alphanumeric characters.`,
  alpha_spaces: (field) => ` ${field} Can only contain alphabetic characters, including spaces`,
  alpha: (field) => ` ${field} Can only contain alphabetic characters`,
  before: (field, [target]) => ` ${field} Must precede ${target}.`,
  between: (field, [min, max]) => ` ${field} Must be between ${min} and ${max}.`,
  confirmed: (field, [confirmedField]) => ` ${field} Cannot match ${confirmedField}`,
  date_between: (field, [min, max]) => ` ${field} Must be between ${min} and ${max}.`,
  date_format: (field, [format]) => ` ${field} Must be in the ${format} format`,
  decimal: (field, [decimals] = ["*"]) => ` ${field} Must be numeric and contain up to ${decimals === "*" ? "" : decimals} decimal places.`,
  digits: (field, [length]) => ` ${field} Must be a ${length} digit number`,
  dimensions: (field, [width, height]) => ` ${field}Must be from ${width} pixels to ${height} pixels.`,
  email: (field) => `Please enter a valid ${field} address*`,
  ext: (field) => ` ${field} Must be a valid file.`,
  image: (field) => ` ${field} Must be a picture.`,
  in: (field) => ` ${field} Must be a valid value.`,
  ip: (field) => ` ${field} Must be a valid address.`,
  max: (field, [length]) => ` ${field} Cannot be greater than ${length} characters`,
  mimes: (field) => ` ${field} Must be a valid file type`,
  min: (field, [length]) => ` ${field} Cannot be less than ${length} characters.`,
  not_in: (field) => ` ${field} Must be a valid value`,
  numeric: (field) => ` ${field} Can only contain numbers.`,
  regex: (field) => ` ${field} Invalid format.`,
  required: (field) => `${field} Cannot be empty`,
  size: (field, [size]) => ` ${field} Must be less than ${size} KB`,
  url: (field) => ` ${field} Is not a valid url.`,
  phone: (field) => ` ${field} Incorrect format.`,
  msgCode: (field) => ` ${field} Incorrect format.`,
  nickname: (field) => ` ${field} Can only contain letters, numbers, or Chinese.`,
  minChar: (field, [length]) => ` ${field} Cannot be less than ${length} characters`,
  maxChar: (field, [length]) => ` ${field} Cannot be greater than ${length} characters`,

  // after: (field, [target]) => ` ${field}必须在${target}之后`,
  // alpha_dash: (field) => ` ${field}能够包含字母数字字符，包括破折号、下划线`,
  // alpha_num: (field) => `${field} 只能包含字母数字字符.`,
  // alpha_spaces: (field) => ` ${field} 只能包含字母字符，包括空格.`,
  // alpha: (field) => ` ${field} 只能包含字母字符.`,
  // before: (field, [target]) => ` ${field} 必须在${target} 之前.`,
  // between: (field, [min, max]) => ` ${field} 必须在${min}~${max}之间.`,
  // confirmed: (field, [confirmedField]) => ` ${field} 不能和${confirmedField}匹配.`,
  // date_between: (field, [min, max]) => ` ${field}必须在${min}和${max}之间.`,
  // date_format: (field, [format]) => ` ${field}必须在在${format}格式中.`,
  // decimal: (field, [decimals] = ["*"]) => ` ${field} 必须是数字的而且最多包含${decimals === "*" ? "" : decimals}位小数.`,
  // digits: (field, [length]) => ` ${field} 必须是${length}位数字`,
  // dimensions: (field, [width, height]) => ` ${field}必须是 ${width} 像素到 ${height} 像素.`,
  // email: (field) => ` ${field} 必须是有效的邮箱.`,
  // ext: (field) => ` ${field} 必须是有效的文件.`,
  // image: (field) => ` ${field} 必须是图片.`,
  // in: (field) => ` ${field} 必须是一个有效值.`,
  // ip: (field) => ` ${field} 必须是一个有效的地址.`,
  // max: (field, [length]) => ` ${field} 不能大于${length}个字.`,
  // mimes: (field) => ` ${field} 必须是有效的文件类型.`,
  // min: (field, [length]) => ` ${field} 不能少于${length}个字.`,
  // not_in: (field) => ` ${field}必须是一个有效值.`,
  // numeric: (field) => ` ${field}只能包含数字.`,
  // regex: (field) => ` ${field} 格式无效.`,
  // required: (field) => `${field}不能为空.`,
  // size: (field, [size]) => ` ${field} 必须小于 ${size} KB.`,
  // url: (field) => ` ${field}不是有效的url.`,
  // phone: (field) => ` ${field} 格式不正确.`,
  // msgCode: (field) => ` ${field} 格式不正确.`,
  // nickname: (field) => ` ${field} 只能包含字母、数字或中文.`,
  // minChar: (field, [length]) => ` ${field}不能小于${length}个字符.`,
  // maxChar: (field, [length]) => ` ${field}不能大于${length}个字符.`,
};

const config = {
  errorBagName: "errors", // change if property conflicts.
  fieldsBagName: "fields",
  delay: 0,
  strict: true,
  enableAutoClasses: false,
  classNames: {
    touched: "touched", // the control has been blurred
    untouched: "untouched", // the control hasn't been blurred
    valid: "valid", // model is valid
    invalid: "invalid", // model is invalid
    pristine: "pristine", // control has not been interacted with
    dirty: "dirty", // control has been interacted with
  },
  dictionary: {
    en: {
      messages: messagesCn,
    },
  },

  events: "blur",
  inject: true,
};
Vue.use(VeeValidate, config);

// 手机号码 自定义校验规则
Validator.extend("phone", {
  getMessage: (field) => field + `请输入正确的手机号码`,
  validate: (value) => {
    console.log("phone");
    // const reg = /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
    const reg = /^([0-9+ ()-]{5,})$/;
    return reg.test(value);
  },
});
Validator.extend("nicknameN", {
  getMessage: (field) => field + " can't be all numbers",
  // validate: (value) => !!/^[a-zA-Z0-9]+$/.test(value),
  validate: (value) => {
    const reg = /[a-zA-Z0-9]*[a-zA-Z]/;
    return reg.test(value);
  },
});

// Validator.extend("email", {

//   validate: (value) => {
//     const reg = replace(/(^\s*)|(\s*$)/g,"");
//     return reg.test(value);
//   },
// });
//验证码校验
// Validator.extend("verifycode", {
//   getMessage: (field) => field + ` field is required`,
//   validate: (value) => {
//     const reg = /^[a-zA-Z0-9]{6}$/;
//     return reg.test(value);
//   },
// });
//验证码校验
// Validator.extend("password", {
//   getMessage: (field, args) => field + ` field is required` + args[0],
//   validate: (value, params) => {
//     return !(value.replace(/[^\x00-\xff]/g, "xx").length < parseInt(params[0]));
//   },
// });
